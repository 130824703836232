import {
    GET_PAYORS_LIST_RES,
    EFT_UPDATE_STATUS_RES
} from "./PayorMix.types";

const INITIAL_STATE = {
    payorsListRes: [],
    eftUpdatedStatusRes: {},

};
const PayorMixReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PAYORS_LIST_RES: {
            return { ...state, ...{ payorsListRes: action.payload } }
        }
        case EFT_UPDATE_STATUS_RES: {
            return { ...state, ...{ eftUpdatedStatusRes: action.payload } }
        }
        default:
            return state;
    }
}

export default PayorMixReducer