import React from "react";
import { Toaster } from "react-hot-toast";
// import { PrivateRoute } from "Utils/private-route";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import Home from "Containers/Home";
import Login from "Containers/Login";

function App() {
  return (
    <div className="app">
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <React.Suspense fallback={"loading"}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/home" component={Home} />
            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
