import { combineEpics } from "redux-observable";
import LoginEpic from "./Login/Login.epic";
import CollectionEpic from "./Collection/Collection.epic";
import DashboardEpic from "./Dashboard/Dashboard.epic";
import ProductionEpic from "./Production/Production.epic";
import BankReconEpic from "./BankRecon/BankRecon.epic";
import PayorMixEpic from "./PayorMix/PayorMix.epic";

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Merina Joy <merina@tensaw.email>
 */

const rootEpic = combineEpics(
  LoginEpic.login,
  LoginEpic.logout,
  DashboardEpic.getClinicDetails,
  DashboardEpic.getProvidersList,
  DashboardEpic.getFacilitiesList,
  DashboardEpic.getServicesList,
  DashboardEpic.getProductionTrend,
  DashboardEpic.getCollectionTrend,
  DashboardEpic.getForecast,
  DashboardEpic.getNotFiledClaims,
  DashboardEpic.getReceivableNet,
  DashboardEpic.getReceivableGross,
  ProductionEpic.getProdGroupByProvider,
  ProductionEpic.getProdGroupByFacility,
  ProductionEpic.getProdGroupByService,
  ProductionEpic.getProductionDetailed,
  ProductionEpic.getClaimLevel,
  ProductionEpic.getNotes,
  ProductionEpic.exportDetails,
  ProductionEpic.downloadDetails,
  ProductionEpic.getExportStatus,
  ProductionEpic.updateApmtType,
  ProductionEpic.getDenialCategory,
  ProductionEpic.getEob,
  ProductionEpic.claimDownloadApi,
  ProductionEpic.payorsList,
  CollectionEpic.getColGroupByProvider,
  CollectionEpic.getColGroupByService,
  CollectionEpic.getColGroupByFacility,
  CollectionEpic.getCollectionDetailed,
  BankReconEpic.getPayments,
  BankReconEpic.updateStatus,
  BankReconEpic.updatePostedDate,
  BankReconEpic.exportPayments,
  BankReconEpic.downloadPayments,
  BankReconEpic.getExportStatus,
  BankReconEpic.updateDepositDate,
  PayorMixEpic.getPayorsList,
  PayorMixEpic.eftUpdatedStatus,
);

export default rootEpic;
