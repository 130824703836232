import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React, { useEffect } from "react";

function CollectionTable({
  values,
  rows,
  billedTotals,
  collectionTotals,
  balanceTotals,
  formatNumber,
  fullMonths,
}) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    console.log("rows", rows);
    console.log("totals", collectionTotals);
    console.log("totals", collectionTotals);
    console.log("mnths", fullMonths);
  }, [rows, collectionTotals, fullMonths]);

  return (
    <React.Fragment>
      <div className="table_container">
        <div className="appsTab__container">
          <TableContainer component={Paper} elevation={0}>
            {values.group === 1 ? (
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow className="headRow">
                    <TableCell className="prod_table_head_cell provider_name">
                      <TableSortLabel>Provider</TableSortLabel>
                    </TableCell>
                    {rows.length > 0
                      ? rows[0].productivityTrendProviderServices.map(
                          (item, i) => (
                            <TableCell
                              key={i}
                              className="prod_table_head_cell month_name"
                              align="center"
                            >
                              {item.month ? months[item.month - 1] : ""}
                            </TableCell>
                          )
                        )
                      : fullMonths?.map((item) => (
                          <TableCell
                            className="prod_table_head_cell month_name"
                            align="center"
                          >
                            {months[item - 1]}
                          </TableCell>
                        ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow className="table_sub_head_row">
                    <TableCell className="provider_name"></TableCell>
                    {[1, 2, 3, 4, 5, 6].map((item) => (
                      <TableCell key={item} className="table_sub_row_cell">
                        <div className="table_sub_row">
                          <div className="table_sub_head_cell billed">
                            Billed
                          </div>
                          <div className="table_sub_head_cell paid">Paid</div>
                          <div className="table_sub_head_cell balance">
                            Balance
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                  {rows
                    ? rows.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell className="provider_name">
                            {item.providerName ? item.providerName : ""}
                            {/* {item.providerFirstName} */}
                          </TableCell>
                          {item.productivityTrendProviderServices &&
                            item.productivityTrendProviderServices.map(
                              (element) => (
                                <TableCell
                                  key={element.trendLineId}
                                  className="table_body_sub_row_cell"
                                >
                                  <div className="table_body_sub_row">
                                    <div className="table_body_sub_cell billed">
                                      $
                                      {element.rowTotal !== 0 && element.billed
                                        ? formatNumber(element.billed)
                                        : "0"}
                                    </div>
                                    <div className="table_body_sub_cell paid">
                                      $
                                      {element.rowTotal !== 0 &&
                                      element.collection
                                        ? formatNumber(element.collection)
                                        : "0"}
                                    </div>
                                    <div className="table_body_sub_cell balance">
                                      $
                                      {element.rowTotal !== 0 && element.balance
                                        ? formatNumber(element.balance)
                                        : "0"}
                                    </div>
                                  </div>
                                </TableCell>
                              )
                            )}
                        </TableRow>
                      ))
                    : ""}

                  <TableRow>
                    <TableCell className="totals_name_column1">TOTAL</TableCell>
                    {[0, 1, 2, 3, 4, 5].map((item) => (
                      <TableCell key={item} className="table_sub_row_cell">
                        <div className="table_sub_row">
                          <div className="table_total_sub_head_cell billed">
                            ${billedTotals[item]}
                          </div>
                          <div className="table_total_sub_head_cell paid">
                            ${collectionTotals[item]}
                          </div>
                          <div className="table_total_sub_head_cell balance">
                            ${balanceTotals[item]}
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            ) : values.group === 2 ? (
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="prod_table_head_cell provider_name">
                      <TableSortLabel>Total</TableSortLabel>
                    </TableCell>
                    {rows.length > 0
                      ? rows[0].productivityTrendProviderServices.map(
                          (item, i) => (
                            <TableCell
                              key={i}
                              className="prod_table_head_cell month_name"
                              align="center"
                            >
                              {item.month ? months[item.month - 1] : ""}
                            </TableCell>
                          )
                        )
                      : ""}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table_sub_head_row">
                    <TableCell className="provider_name"></TableCell>
                    {[1, 2, 3, 4, 5, 6].map((item) => (
                      <TableCell key={item} className="table_sub_row_cell">
                        <div className="table_sub_row">
                          <div className="table_sub_head_cell billed">
                            Billed
                          </div>
                          <div className="table_sub_head_cell paid">Paid</div>
                          <div className="table_sub_head_cell balance">
                            Balance
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                  {rows &&
                    rows.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell className="provider_name">
                          {item.facilityName ? item.facilityName : ""}
                        </TableCell>
                        {item.productivityTrendProviderServices &&
                          item.productivityTrendProviderServices.map(
                            (element) => (
                              <TableCell
                                className="table_body_sub_row_cell"
                                // key={element.trendLineId}
                              >
                                <div className="table_body_sub_row">
                                  <div className="table_body_sub_cell billed">
                                    $
                                    {element.rowTotal !== 0 && element.billed
                                      ? formatNumber(element.billed)
                                      : "0"}
                                  </div>
                                  <div className="table_body_sub_cell paid">
                                    $
                                    {element.rowTotal !== 0 &&
                                    element.collection
                                      ? formatNumber(element.collection)
                                      : "0"}
                                  </div>
                                  <div className="table_body_sub_cell balance">
                                    $
                                    {element.rowTotal !== 0 && element.balance
                                      ? formatNumber(element.balance)
                                      : "0"}
                                  </div>
                                </div>
                              </TableCell>
                            )
                          )}
                      </TableRow>
                    ))}

                  <TableRow>
                    <TableCell className="totals_name_column1">TOTAL</TableCell>
                    {[0, 1, 2, 3, 4, 5].map((item) => (
                      <TableCell key={item} className="table_sub_row_cell">
                        <div className="table_sub_row">
                          <div className="table_total_sub_head_cell billed">
                            ${billedTotals[item]}
                          </div>
                          <div className="table_total_sub_head_cell paid">
                            ${collectionTotals[item]}
                          </div>
                          <div className="table_total_sub_head_cell balance">
                            ${balanceTotals[item]}
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className="prod_table_head_cell provider_name">
                      <TableSortLabel>Total</TableSortLabel>
                    </TableCell>
                    {rows.length > 0
                      ? rows[0].productivityTrendProviderServices.map(
                          (item, i) => (
                            <TableCell
                              key={i}
                              className="prod_table_head_cell"
                              align="center"
                            >
                              {item.month ? months[item.month - 1] : ""}
                            </TableCell>
                          )
                        )
                      : ""}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow className="table_sub_head_row">
                    <TableCell className="provider_name"></TableCell>
                    {[1, 2, 3, 4, 5, 6].map((item) => (
                      <TableCell key={item} className="table_sub_row_cell">
                        <div className="table_sub_row">
                          <div className="table_sub_head_cell billed">
                            Billed
                          </div>
                          <div className="table_sub_head_cell paid">Paid</div>
                          <div className="table_sub_head_cell balance">
                            Balance
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                  {rows &&
                    rows.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell className="provider_name">
                          {item.serviceName ? item.serviceName : ""}
                        </TableCell>
                        {item.productivityTrendProviderServices &&
                          item.productivityTrendProviderServices.map(
                            (element) => (
                              <TableCell
                                className="table_body_sub_row_cell"
                                // key={element.trendLineId}
                              >
                                <div className="table_body_sub_row">
                                  <div className="table_body_sub_cell billed">
                                    $
                                    {element.rowTotal !== 0 && element.billed
                                      ? formatNumber(element.billed)
                                      : "0"}
                                  </div>
                                  <div className="table_body_sub_cell paid">
                                    $
                                    {element.rowTotal !== 0 &&
                                    element.collection
                                      ? formatNumber(element.collection)
                                      : "0"}
                                  </div>
                                  <div className="table_body_sub_cell balance">
                                    $
                                    {element.rowTotal !== 0 && element.balance
                                      ? formatNumber(element.balance)
                                      : "0"}
                                  </div>
                                </div>
                              </TableCell>
                            )
                          )}
                      </TableRow>
                    ))}

                  <TableRow>
                    <TableCell className="totals_name_column1">TOTAL</TableCell>
                    {[0, 1, 2, 3, 4, 5].map((item) => (
                      <TableCell key={item} className="table_sub_row_cell">
                        <div className="table_sub_row">
                          <div className="table_total_sub_head_cell billed">
                            ${billedTotals[item]}
                          </div>
                          <div className="table_total_sub_head_cell paid">
                            ${collectionTotals[item]}
                          </div>
                          <div className="table_total_sub_head_cell balance">
                            ${balanceTotals[item]}
                          </div>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CollectionTable;
