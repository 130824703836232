import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dashboard from "components/Pages/Dashboard";
import { Button } from "@material-ui/core";
import primroseLogo from "../../assets/images/primrose-brand-logo-sm-0.5x.png";
import { SidebarData } from "./SidebarData";
import Production from "components/Pages/Production";
import ProductionDetailed from "components/Pages/ProductionDetailed";
import Collection from "components/Pages/Collection";
import CollectionDetailed from "components/Pages/CollectionDetailed";
import { logout } from "Redux/Login/Login.actions";
import { getClinicDetails } from "Redux/Dashboard/Dashboard.actions";
import BankRecon from "components/Pages/BankRecon";
import AppLogout from "components/AppLogout";
import DenialReport from "components/Pages/DenialReport";
import PayorMix from "components/Pages/PayorMIx"
import "./style.css";

function Home(props) {
  const [page, setPage] = useState(0);
  const [clinicId, setclinicId] = useState();
  let currentPage = localStorage.getItem("page")
    ? parseInt(localStorage.getItem("page"))
    : page;

  const handleClick = (num) => {
    localStorage.setItem("page", num);
    setPage(num);
  };

  const handleLogout = () => {
    props.logout(localStorage.getItem("refreshToken"));
  };

  // useEffect(() => {
  //   localStorage.setItem("page", 0);
  // }, []);

  useEffect(() => {
    let clinic_id = localStorage.getItem("clinic_id");
    setclinicId(clinic_id);
    if (!clinic_id) window.location.href = "/login";
  }, []);

  useEffect(() => {
    if (
      props.loginData &&
      props.loginData.logoutResponse &&
      props.loginData.logoutResponse.response &&
      props.loginData.logoutResponse.response.data
    ) {
      const { data } = props.loginData.logoutResponse.response;
      if (data) window.location.href = "/login";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loginData.logoutResponse]);

  return (
    <AppLogout logout={handleLogout}>
      <div className="home__page scrollbarY--custom">
        <header className="home__header">
          <div className="home__header__bar">
            <a href="#0">
              <img
                src={primroseLogo}
                alt="primrose-health-logo"
                className="home__header__logo"
              />
            </a>
            <div className="home_logoutBtn_wrap">
              <Button
                className="home_logoutBtn"
                variant="contained"
                color="primary"
                size="small"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </div>
        </header>

        <main className="home_main">
          <div className="home_container">
            <div className="sidebar">
              <ul className="sidebar_list">
                {SidebarData.map((val) => {
                  return (
                    <li
                      key={val.value}
                      // className="sidebar_row"
                      className={
                        currentPage === val.value
                          ? "sidebar_row_active"
                          : "sidebar_row"
                      }
                      onClick={() => handleClick(val.value)}
                    >
                      <div className="sidebar_item">
                        <span className="bullet_wrap">
                          <div className="bullet"></div>
                        </span>{" "}
                        <div className="sidebar_title">{val.title}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            {currentPage === 0 ? (
              <div className="page_container">
                <Dashboard />
              </div>
            ) : currentPage === 1 ? (
              <div className="page_container">
                <Production />
              </div>
            ) : currentPage === 2 ? (
              <div className="page_container">
                <ProductionDetailed />
              </div>
            ) : currentPage === 3 ? (
              <div className="page_container">
                <Collection />
              </div>
            ) : currentPage === 4 ? (
              <div className="page_container">
                <CollectionDetailed />
              </div>
            ) : currentPage === 5 ? (
              <div className="page_container">
                {/* <div className="empty"></div> */}
                <BankRecon clinicId={clinicId} />
              </div>
            ) : currentPage === 6 ? (
              <div className="page_container">
                {/* <div className="empty"></div> */}
                <DenialReport />
              </div>
            ) : currentPage === 7 ? (
              <div className="page_container">
                <PayorMix />
              </div>
            ) : (
              ""
            )}
          </div>
        </main>
      </div>
    </AppLogout>
  );
}

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  getClinicDetails: (values) => dispatch(getClinicDetails(values)),
  logout: (values) => dispatch(logout(values)),
});
Home.propTypes = {
  dashboardData: PropTypes.object,
  loginData: PropTypes.object,
  getClinicDetails: PropTypes.func,
  logout: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
