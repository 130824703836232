export const Apis = {
  Login_login: "trillium-clinic-service/v1/authentication/clinic/user/login",
  Login_logout: "trillium-clinic-service/v1/authentication/clinic/user/logout",

  // Dashboard

  Dashboard_getClinicDetails: "dashboard-service-api/v1/clinic",
  Dashboard_getProvidersList: "dashboard-service-api/v1/provider/lists/all",
  Dashboard_getFacilitiesList: "dashboard-service-api/v1/location/lists/all",
  Dashboard_getProductionTrend:
    "dashboard-service-api/v1/dashboard/getTrendLineBilled",
  Dashboard_getCollectionTrend:
    "dashboard-service-api/v1/dashboard/getTrendLineCollection",
  Dashboard_getForecast:
    "dashboard-service-api/v1/dashboard/getTrendLineCollectionForeCast",
  Dashboard_getNotFiledClaims:
    "dashboard-service-api/v1/dashboard/getNotFieldClaims",
  Dashboard_getReceivableNet: "dashboard-service-api/v1/dashboard/getARNet",
  Dashboard_getReceivableGross: "dashboard-service-api/v1/dashboard/getARGross",
  // Dashboard_getPayorsList:"trillium-internal-service/v1/common/priorAuth/cache?applicationId=4",
  Dashboard_getPayorsList:"dashboard-service-api/v1/EftTab/payors",
  // https://api.primrose.health/dashboard-service-api/v1/EftTab/payors?clinicId=93422

  // Production

  Production_getProductionSummaryCustom:
    "dashboard-service-api/v1/dashboard/getProviderProductivityReportNew",
  Production_getProductionSummary:
    "dashboard-service-api/v1/dashboard/getProviderProductivity",
  Production_getProductionDetailed:
    "dashboard-service-api/v1/details/getNewProviderProductivityReport",
  Production_getClaimLevel: "dashboard-service-api/v1/details/cptreport",
  Production_getNotes: "dashboard-service-api/v1/details/notes",

  // Collection

  Collection_getCollectionSummaryCustom:
    "dashboard-service-api/v1/dashboard/getProviderProductivityReportNew",
  Collection_getCollectionSummary:
    "dashboard-service-api/v1/dashboard/getProviderProductivity",
  Collection_getCollectionDetailed:
    "dashboard-service-api/v1/details/getNewProviderProductivityReport",
};
