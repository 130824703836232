import { GET_PAYORS_LIST, GET_PAYORS_LIST_RES, EFT_UPDATE_STATUS, EFT_UPDATE_STATUS_RES } from "./PayorMix.types"

export const getPayorsList = (data) => ({
    type: GET_PAYORS_LIST,
    payload: data,
});

export const getPayorsListRes = (data) => ({
    type: GET_PAYORS_LIST_RES,
    payload: data,
});
export const eftUpdatedStatus = (data) => ({
    type: EFT_UPDATE_STATUS,
    payload: data,
});
export const eftUpdatedStatusRes = (data) => ({
    type: EFT_UPDATE_STATUS_RES,
    payload: data,
});