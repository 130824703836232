import {
  LOGIN_RES,
  LOGOUT_RES,
} from "./Login.types";

const INITIAL_STATE = {
  loginResponse: {},
  logoutResponse: null,
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const LoginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RES: {
      return { ...state, ...{ login: true, loginResponse: action.payload } };
    }
    case LOGOUT_RES: {
      return { ...state, ...{ logoutResponse: action.payload } };
    }
    default:
      return state;
  }
};

export default LoginReducer;
