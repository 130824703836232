import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  claimDownload,
  claimDownloadRes,
  getEob,
} from "Redux/Production/Production.actions";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import "./style.css";
import NoDataImage from "../../../assets/images/nodata-found.png";

const useStyles = makeStyles((theme) => ({
  TableHeight: {
    maxHeight: "330px !important",
  },
  customTableContainer: {
    overflowX: "initial",
  },
  EobContainer: {
    display: "flex",
    padding: "2rem",
    flexDirection: "column",
  },
  headingContainer: {
    display: "flex",
    gap: 20,
  },
  heading: {
    fontWeight: 500,
    fontSize: 17,
  },
  closebtn: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

function Eob(props) {
  const classes = useStyles();
  const [eobList, setEobList] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);

  useEffect(() => {
    if (props.prodData && props.prodData.eob && props.prodData.eob.response) {
      if (props.prodData.eob.response.responseCode === 0) {
        let data = props.prodData.eob.response.data;
        // console.log(data);
        setEobList(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.eob.response]);

  const downloadFiles = (content, fileName, contentType) => {
    if (!contentType) contentType = "application/octet-stream";
    var a = document.createElement("a");
    var blob = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    if (props.prodData.claimFile) {
      let blobData = props.prodData.claimFile.response;
      let fileName = currentFile;
      let fileType = currentFile.split(".")[1];
      downloadFiles(blobData, fileName, fileType);
      props.claimDownloadRes(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.claimFile]);

  const handleView = (x) => {
    // console.log(x);
    setCurrentFile(x.fileName);
    props.claimDownload(x);
  };

  const formatNumber = (number) => {
    return Math.ceil(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="container">
      <div className="closeBtn">
        <Button
          style={{ color: "white" }}
          variant="contained"
          color="primary"
          onClick={() => props.handleCloseEob()}
        >
          Close
        </Button>
        <span style={{ fontWeight: "bold" }}>EOB</span>
      </div>
      {eobList.length !== 0 ? (
        <TableContainer className={classes.TableHeight}>
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.TableHead}>
              <TableRow>
                <TableCell className={classes.headCell}>Posted</TableCell>
                <TableCell className={classes.headCell}>Payer</TableCell>
                <TableCell className={classes.headCell}>Check Date</TableCell>
                <TableCell className={classes.headCell}>Amount</TableCell>
                <TableCell className={classes.headCell}>Check #</TableCell>
                <TableCell className={classes.headCell}>Type</TableCell>
                <TableCell className={classes.headCell}>Unapplied</TableCell>
                <TableCell className={classes.headCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eobList.map((x, i) => (
                x.fileId === 0 ? "" :
                  <TableRow key={`row${i}`}>
                    <TableCell align={"left"}>{x.postDate}</TableCell>
                    <TableCell align={"left"}>{x.payerName}</TableCell>
                    <TableCell align={"left"}>{x.checkDate}</TableCell>
                    <TableCell align={"left"}>
                      ${x.amount}
                    </TableCell>
                    <TableCell align={"left"}>{x.checkNo}</TableCell>
                    <TableCell align={"left"}>{x.payerType}</TableCell>
                    <TableCell align={"left"}>
                      ${formatNumber(x.unAppliedAmount)}
                    </TableCell>
                    <TableCell align={"left"}>
                      <div className="account_table_actions">
                        <Button
                          onClick={() => handleView(x)}
                          variant="outlined"
                          color="primary"
                          type="submit"
                          size="small"
                        >
                          View
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="noData">
          <img className="noDataImage" src={NoDataImage} alt="" />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  prodData: state.production,
});

const mapDispatchToProps = (dispatch) => ({
  getEob: (values) => dispatch(getEob(values)),
  claimDownload: (values) => dispatch(claimDownload(values)),
  claimDownloadRes: (values) => dispatch(claimDownloadRes(values)),
});

Eob.propTypes = {
  prodData: PropTypes.object,
  claimDownload: PropTypes.func,
  claimDownloadRes: PropTypes.func,
  getEob: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Eob);
