import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  eftUpdatedStatus,
  getPayorsList,
} from "Redux/PayorMix/PayorMix.actions";
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { toast } from "react-hot-toast";
import "./style.css";

function PayorMix(props) {
  const clinic_id = localStorage.getItem("clinic_id");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const [values, setValues] = useState({
    clinicId: clinic_id,
    offset: 0,
    order: "DESC",
    sortId: 3,
  });

  useEffect(() => {
    // props.getPayorsList()
    if (
      props.payorData &&
      props.payorData.payorsListRes &&
      props.payorData.payorsListRes.response
    ) {
      if (props.payorData.payorsListRes.response.responseCode === 0) {
        let data = props.payorData.payorsListRes.response.data;
        // console.log(data);
        setRows(data.results);
        setTotalCount(data.totalRecords);
        setRowsPerPage(data.limit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payorData.payorsListRes.response]);

  useEffect(() => {
    if (
      props.payorData &&
      props.payorData.eftUpdatedStatusRes &&
      props.payorData.eftUpdatedStatusRes.response
    ) {
      if (props.payorData.eftUpdatedStatusRes.response.responseCode === 0) {
        toast.success("Status Updated");
        props.getPayorsList(values);
      } else {
        toast.error("Could not update status");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.payorData.eftUpdatedStatusRes.response]);

  useEffect(() => {
    props.getPayorsList({ ...values, clinicId: clinic_id, offset: page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log(newPage);
    setValues({ ...values, offset: page });
  };

  const handleEftStatusChange = (e, item) => {
    props.eftUpdatedStatus({
      clinicId: clinic_id,
      bussinessId: item.businessId,
      eftStatus: e.target.value,
      networkStatus: item.networkStatus,
      eraStatus: item.eraStatus,
    });
  };
  
  const handleNetworkStatusChange = (e, item) => {
    props.eftUpdatedStatus({
      clinicId: clinic_id,
      bussinessId: item.businessId,
      eftStatus: item.eftStatus,
      networkStatus: e.target.value,
      eraStatus: item.eraStatus,
    });
  };
  return (
    <div className="payor_root">
      <div className="table_container">
        <TableContainer
          component={Paper}
          elevation={0}
          className="payor_table_container"
        >
          <Table stickyHeader size="small" aria-label="table">
            <TableHead className="payor_table_head" elevation={0}>
              <TableRow>
                <TableCell className="payor_table_head_cell">Payor</TableCell>
                <TableCell className="payor_table_head_cell">
                  Network Status
                </TableCell>
                <TableCell className="payor_table_head_cell">
                  EFT Status
                </TableCell>
                <TableCell className="payor_table_head_cell">
                  Quarter Billed
                </TableCell>
                <TableCell className="payor_table_head_cell">
                  Quarter Billed(%)
                </TableCell>
                <TableCell className="payor_table_head_cell">
                  Claim Count
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 &&
                rows.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell className="payor_name_cell">
                      {item.name}
                    </TableCell>
                    <TableCell>
                      <div className="status_select_container_row">
                        <FormControl className="status_select_row">
                          <Select
                            value={item.networkStatus}
                            onChange={(e) =>
                              handleNetworkStatusChange(e, item)
                            }
                            size="small"
                          >
                            <MenuItem value={0}>Not started</MenuItem>
                            <MenuItem value={1}>In network</MenuItem>
                            <MenuItem value={2}>In progress</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="status_select_container_row">
                        <FormControl className="status_select_row">
                          <Select
                            value={item.eftStatus}
                            onChange={(e) => handleEftStatusChange(e, item)}
                            size="small"
                          >
                            <MenuItem value={0}>Not started</MenuItem>
                            <MenuItem value={1}>In network</MenuItem>
                            <MenuItem value={2}>In progress</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </TableCell>
                    {/* <TableCell>{item.eraStatus}</TableCell>{" "} */}
                    {/* <TableCell>{item.eftStatus}</TableCell>{" "} */}
                    <TableCell>$ {(item.quarterlyBilled).toLocaleString()}</TableCell>{" "}
                    <TableCell>{(item.billedPercentage).toFixed(2)} %</TableCell>{" "}
                    <TableCell>{item.claimCount}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // rowsPerPageOptions={[100, 150, 200, 250, 500]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  payorData: state.payorMix,
});

const mapDispatchToProps = (dispatch) => ({
  getPayorsList: (values) => dispatch(getPayorsList(values)),
  eftUpdatedStatus: (values) => dispatch(eftUpdatedStatus(values)),
});

PayorMix.propTypes = {
  payorData: PropTypes.object,
  getPayorsList: PropTypes.func,
  eftUpdatedStatus: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayorMix);
