import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
// import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import { Apis } from "Redux/APIs";
import environment from "../../environments/environment";

import {
  REQUEST_CANCEL,
  PROD_GROUP_BY_PROVIDER,
  PROD_GROUP_BY_SERVICE,
  PRODUCTION_DETAILED,
  CLAIM_LEVEL,
  NOTES,
  EXPORT_DETAILS,
  DOWNLOAD_DETAILS,
  GET_EXPORT_STATUS,
  UPDATE_APMT_TYPE,
  DENIAL_CATEGORY,
  PROD_GROUP_BY_FACILITY,
  EOB,
  CLAIM_DOWNLOAD,
  PAYORS_LIST,
} from "./Production.types";

import {
  getProdGroupByProviderRes,
  getProdGroupByServiceRes,
  getProductionDetailedRes,
  getClaimLevelRes,
  getNotesRes,
  exportDetailsRes,
  downloadDetailsRes,
  getExportStatusRes,
  updateApmtTypeRes,
  getDenialDetailedRes,
  getProdGroupByFacilityRes,
  getEobRes,
  claimDownloadRes,
  payorsListRes,
} from "./Production.actions";

const baseUrl = environment.baseUrl;
const clinicBaseUrl = environment.clinicBaseUrl;
const ProductionEpic = {};

ProductionEpic.getProdGroupByProvider = (action$) =>
  action$.pipe(
    ofType(PROD_GROUP_BY_PROVIDER),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/dashboard-service-api/v1/production/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        // url: `https://stagingnew.trillium.health/dashboard-service-api/v1/production/summery/providerProductivity/83622?filterValue=${action.payload.filterValue}&groupBy=${action.payload.group}&filter=${action.payload.filter}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getProdGroupByProviderRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.getProdGroupByFacility = (action$) =>
  action$.pipe(
    ofType(PROD_GROUP_BY_FACILITY),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/dashboard-service-api/v1/production/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        // url: `https://stagingnew.trillium.health/dashboard-service-api/v1/production/summery/providerProductivity/83622?filterValue=${action.payload.filterValue}&groupBy=${action.payload.group}&filter=${action.payload.filter}`,

        method: "GET",
      }).pipe(
        map(
          (response) => getProdGroupByFacilityRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.getProdGroupByService = (action$) =>
  action$.pipe(
    ofType(PROD_GROUP_BY_SERVICE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/dashboard-service-api/v1/production/summery/providerProductivity/${action.payload.clinicId}?groupBy=${action.payload.group}&filter=${action.payload.filter}&filterValue=${action.payload.filterValue}`,
        // url: `https://stagingnew.trillium.health/dashboard-service-api/v1/production/summery/providerProductivity/83622?filterValue=${action.payload.filterValue}&groupBy=${action.payload.group}&filter=${action.payload.filter}`,

        method: "GET",
      }).pipe(
        map(
          (response) => getProdGroupByServiceRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.getProductionDetailed = (action$) =>
  action$.pipe(
    ofType(PRODUCTION_DETAILED),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "Content-Type": "application/json",
        },
        url: `${baseUrl}/${Apis.Production_getProductionDetailed}/${action.payload.iclinicId}?offset=${action.payload.offset}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getProductionDetailedRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.getDenialCategory = (action$) =>
  action$.pipe(
    ofType(DENIAL_CATEGORY),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          "Content-Type": "application/json",
        },
        url: `${baseUrl}/${Apis.Production_getProductionDetailed}/${action.payload.iclinicId}?offset=${action.payload.offset}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getDenialDetailedRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.getClaimLevel = (action$) =>
  action$.pipe(
    ofType(CLAIM_LEVEL),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_getClaimLevel}/${action.payload.clinicId}?claimId=${action.payload.claimId}`,
        // url: `${baseUrl}/${Apis.Production_getClaimLevel}/${
        //   action.payload.clinicId
        // }?claimId=${33091}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getClaimLevelRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.getEob = (action$) =>
  action$.pipe(
    ofType(EOB),
    switchMap((action) =>
      UtilsAjax({
        url: `https://api.trillium.health/trillium-clinic-service/v1/payment/eob/list/${action.payload.claimId}?patientId=${action.payload.patientId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getEobRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.claimDownloadApi = (action$) =>
  action$.pipe(
    ofType(CLAIM_DOWNLOAD),
    switchMap((action) =>
      UtilsAjax({
        // url: `https://stagingnew.trillium.health/trillium-internal-service/v1/coderPortal/download?claimId=${2}&clinicId=${83622}&fileId=${108387811}&fileName=${"Eob_test.pdf"}`,
        url: `https://api.primrose.health/trillium-internal-service/v1/coderPortal/download?claimId=${action.payload.claimId}&clinicId=${action.payload.clinicId}&fileId=${action.payload.fileId}&fileName=${action.payload.fileName}`,
        method: "GET",
        responseType: "blob",
      }).pipe(
        map(
          (response) => claimDownloadRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

ProductionEpic.getNotes = (action$) =>
  action$.pipe(
    ofType(NOTES),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Production_getNotes}/${action.payload.clinicId}?claimId=${action.payload.claimId}&providerId=${action.payload.providerId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getNotesRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.exportDetails = (action$) =>
  action$.pipe(
    ofType(EXPORT_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/dashboard-service-api/v1/details/export`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => exportDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.downloadDetails = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/dashboard-service-api/v1/dashboard/download?filePath=${action.payload}`,
        method: "GET",
        responseType: "blob",
      }).pipe(
        map(
          (response) => downloadDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.getExportStatus = (action$) =>
  action$.pipe(
    ofType(GET_EXPORT_STATUS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/dashboard-service-api/v1/dashboard/status/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getExportStatusRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

ProductionEpic.updateApmtType = (action$) =>
  action$.pipe(
    ofType(UPDATE_APMT_TYPE),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/dashboard-service-api/v1/details/claim/${action.payload.clinicId}?apptTypeId=${action.payload.apptTypeId}&claimId=${action.payload.claimId}`,
        method: "PUT",
      }).pipe(
        map(
          (response) => updateApmtTypeRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

  ProductionEpic.payorsList = (action$) =>
  action$.pipe(
    ofType(PAYORS_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getPayorsList}?clinicId=93422`,
        method: "GET",
      }).pipe(
        map(
          (response) => payorsListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default ProductionEpic;
