import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getColGroupByProvider,
  getColGroupByFacility,
  getColGroupByService,
  // getServicesList,
} from "Redux/Collection/Collection.actions";
import {
  getProvidersList,
  getServicesList,
  getFacilitiesList,
} from "Redux/Dashboard/Dashboard.actions";
// import Line from "components/Charts/Line";
import BarGroup from "components/Charts/BarGroup";

import "./style.css";
import { collectionSummaryContext } from "context/CollSummaryContext";
import { prodDetailsContext } from "context/ProdFilterContext";
import { ToastBar, toast } from "react-hot-toast";
import html2canvas from "html2canvas";
import Line from "components/Charts/Line";
import LineGraph from "./graphs/LineGraph";
import { GetApp } from "@material-ui/icons";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const filters = [
  { name: "Provider", value: 1 },
  { name: "Facility", value: 2 },
  { name: "Service", value: 3 },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

// const halfMonths = ["January", "February", "March", "April", "May", "June"];

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function Collection(props) {
  const classes = useStyles();
  const clinic_id = localStorage.getItem("clinic_id");
  const {
    period,
    setPeriod,
    chartProviders,
    setChartProviders,
    chartServices,
    setChartServices,
    providers,
    setProviders,
    provider,
    setProvider,
    services,
    setServices,
    service,
    setService,
    group,
    setGroup,
    rows,
    setRows,
    halfRows,
    setHalfRows,
    totals,
    setTotals,
    halfTotals,
    setHalfTotals,
    chartData,
    setChartData,
    halfChartData,
    setHalfChartData,
    values,
    setValues,
    // facilities,
    // setFacilities,
    selectedFacility,
    setSelectedFacility,
    // chartFacilities,
  } = useContext(collectionSummaryContext);

  const [lineData, setLineData] = useState([]);
  const [fullMonths, setFullMonths] = useState([]);
  const [halfLineData, setHalfLineData] = useState([]);
  // const [chartFacilities, setChartFacilities] = useState([]);
  const [chartFacilities, setChartFacilities] = useState([
    "OFFICE",
    "HOSPITAL",
  ]);
  const [filter, setFilter] = useState(2);

  // const [period, setPeriod] = useState(1);
  // const [chartProviders, setChartProviders] = useState([]);
  // const [chartServices, setChartServices] = useState([]);
  // const [providers, setProviders] = useState([
  //   {
  //     name: "All providers",
  //     value: -1,
  //   },
  // ]);
  // const [provider, setProvider] = useState(-1);
  // const [services, setServices] = useState([
  //   {
  //     name: "All services",
  //     value: -1,
  //   },
  // ]);
  // const [service, setService] = useState(-1);
  // const [group, setGroup] = useState(1);
  // const [rows, setRows] = useState([]);
  // const [halfRows, setHalfRows] = useState([]);
  // const [totals, setTotals] = useState([]);
  // const [halfTotals, setHalfTotals] = useState([]);
  // const [chartData, setChartData] = useState([]);
  // const [halfChartData, setHalfChartData] = useState([]);
  // const [values, setValues] = useState({
  //   period: 1,
  //   group: 1,
  //   provider: -1,
  //   service: -1,
  // });

  const periods = [
    { name: "Last 6 months", value: 1 },
    { name: "Last 12 months", value: 2 },
  ];

  const facilityTypes = [
    { name: "All Facility Types", value: -1 },
    { name: "OFFICE", value: 1 },
    { name: "HOSPITAL", value: 3 },
  ];

  const groups = [
    { name: "Group by Provider", value: 1 },
    { name: "Group by Facility", value: 2 },
    { name: "Group by Service", value: 3 },
  ];

  const handleGroupChange = (event) => {
    let value = event.target.value;
    if (value === 1 && filter === 1) {
      setFilter(2);
    }
    if (value === 2 && filter === 2) {
      setFilter(1);
    }
    if (value === 3 && filter === 3) {
      setFilter(1);
    }
    setGroup(value);
  };

  const handleFilterChange = (event) => {
    let value = event.target.value;
    // setSelectedFacility("");
    // setProvider("");
    // setService("");
    setFilter(value);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleServiceChange = (event) => {
    // setSelectedFacility("");
    // setProvider("");
    setService(event.target.value);
  };
  const handleProviderChange = (event) => {
    // setSelectedFacility("");
    // setService("");
    setProvider(event.target.value);
  };

  const handleFacilityChange = (event) => {
    setSelectedFacility(event.target.value);
  };

  const handleGroupByProvider = (clinicId, group, filter, filterValue) => {
    setValues({
      period: period,
      group: group,
      provider: provider,
      service: service,
      facility: selectedFacility,
    });
    props.getColGroupByProvider({
      clinicId: clinicId,
      group: group,
      filter: filter,
      filterValue: filterValue,
    });
  };

  const handleGroupByFacility = (clinicId, group, filter, filterValue) => {
    setValues({
      period: period,
      group: group,
      provider: provider,
      service: service,
      facility: selectedFacility,
    });

    props.getColGroupByFacility({
      clinicId: clinic_id,
      group: group,
      filter: filter,
      filterValue: filterValue,
    });
  };

  const handleGroupByService = (clinicId, group, filter, filterValue) => {
    setValues({
      period: period,
      group: group,
      provider: provider,
      service: service,
      facility: selectedFacility,
    });
    props.getColGroupByService({
      clinicId: clinicId,
      group: group,
      filter: filter,
      filterValue: filterValue,
    });
  };

  const handleGraphDownload = async () => {
    const element = document.getElementById("graph"),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/png"),
      link = document.createElement("a");
    link.href = data;
    link.download = "downloaded-image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFilter = () => {
    console.log(period, group, provider, service);
    if (group === 1) {
      //facility is selected
      if (filter === 2) {
        if (selectedFacility !== "") {
          handleGroupByProvider(clinic_id, 1, 2, selectedFacility);
        } else {
          toast.error("Select atleast one filter");
        }
      }
      //service is selected
      if (filter === 3) {
        if (service !== "") {
          handleGroupByProvider(clinic_id, 1, 3, service);
        } else {
          toast.error("Select atleast one filter");
        }
      }
    }
    //group by facility
    if (group === 2) {
      if (filter === 1) {
        handleGroupByFacility(clinic_id, 2, 1, provider);
      }
      //service is selected
      if (filter === 3) {
        handleGroupByFacility(clinic_id, 2, 3, service);
      }
    }
    //gorup by service
    if (group === 3) {
      //faclitity is selected
      if (filter === 2) {
        handleGroupByService(clinic_id, 3, 2, selectedFacility);
      }
      //provider is selected
      if (filter === 1) {
        handleGroupByService(clinic_id, 3, 1, provider);
      }
    }
  };

  useEffect(() => {
    props.getProvidersList(clinic_id);
    props.getServicesList({
      start: 0,
      limit: 100,
    });
    props.getFacilitiesList(clinic_id);
    if (group === 1) {
      props.getColGroupByProvider({
        clinicId: clinic_id,
        group: 1,
        filter: 2,
        filterValue: -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.facilitiesList &&
      props.dashboardData.facilitiesList.response
    ) {
      if (props.dashboardData.facilitiesList.response.responseCode === 0) {
        let data = props.dashboardData.facilitiesList.response.data;
        let facilitiesArray = data
          .filter((item) => item.active === 1)
          .map((item) => {
            return {
              name: item.locationName + ", " + item.city,
              value: item.id,
            };
          });
        // facilitiesArray.push({ name: "All Facilities", values: -1 });
        let newArray = [
          { name: "All Facilities", value: -1 },
          ...facilitiesArray,
        ];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.facilitiesList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.providersList &&
      props.dashboardData.providersList.response
    ) {
      if (props.dashboardData.providersList.response.responseCode === 0) {
        let data = props.dashboardData.providersList.response.data;
        let providersArray = data
          .filter((item) => item.active === 1)
          .map((item) => {
            return {
              name:
                item.firstName + " " + item.middleName + " " + item.lastName,
              value: item.id,
            };
          });
        // providersArray.unshift({
        //   name: "All providers",
        //   value: -1,
        // });
        let newArray = [
          {
            name: "All providers",
            value: -1,
          },
          ...providersArray,
        ];
        setProviders(newArray);
        // let demoProviders = [
        //   { name: "All providers", value: -1 },
        //   { name: "Sanjeev Nair", value: 208 },
        //   { name: "Nikhil Nalluri", value: 209 },
        //   { name: "John Hibbits", value: 210 },
        //   { name: "Brian Schwartz", value: 211 },
        //   { name: "Venkata Aligeti", value: 212 },
        //   { name: "Vinit Lal", value: 213 },
        //   { name: "Kelsie Reekie", value: 214 },
        //   { name: "Randall Gale", value: 215 },
        //   { name: "Deepak Asti", value: 216 },
        // ];
        // setProviders(demoProviders);
        setChartProviders(
          data.map((item) => item.firstName + " " + item.lastName)
        );
        // setChartProviders(demoProviders.map((item) => item.name));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.providersList.response]);

  // useEffect(() => {
  //   if (
  //     props.dashboardData &&
  //     props.dashboardData.providersList &&
  //     props.dashboardData.providersList.response
  //   ) {
  //     if (props.dashboardData.providersList.response.responseCode === 0) {
  //       let data = props.dashboardData.providersList.response.data;
  //       let providersArray = data
  //         .filter((item) => item.active === 1)
  //         .map((item) => {
  //           return {
  //             name:
  //               item.firstName + " " + item.middleName + " " + item.lastName,
  //             value: item.id,
  //           };
  //         });
  //       setProviders(providersArray);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.dashboardData.providersList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.servicesList &&
      props.dashboardData.servicesList.response
    ) {
      if (props.dashboardData.servicesList.response.responseCode === 0) {
        let data = props.dashboardData.servicesList.response.data.results;
        let servicesArray = data.map((item) => {
          return {
            name: item.name,
            value: item.id,
          };
        });
        // servicesArray.unshift({
        //   name: "All services",
        //   value: -1,
        // });
        // let newArray = [
        //   {
        //     name: "All services",
        //     value: -1,
        //   },
        //   ...servicesArray,
        // ];
        // setServices(newArray);
        setChartServices(servicesArray.map((item) => item.name));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.servicesList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.servicesList &&
      props.dashboardData.servicesList.response
    ) {
      if (props.dashboardData.servicesList.response.responseCode === 0) {
        let data = props.dashboardData.servicesList.response.data.results;
        // console.log(data);
        let servicesArray = data.map((item) => {
          return {
            name: item.name,
            value: item.id,
          };
        });
        // servicesArray.push({ name: "All Facilities", values: -1 });
        let newArray = [{ name: "All Services", value: -1 }, ...servicesArray];
        setServices(newArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.servicesList.response]);

  useEffect(() => {
    if (
      props.colData &&
      props.colData.ColGroupByProvider &&
      props.colData.ColGroupByProvider.response
    ) {
      if (props.colData.ColGroupByProvider.response.responseCode === 0) {
        let data = props.colData.ColGroupByProvider.response.data;
        const getSum = (rowArray) => {
          let sum = rowArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          return sum;
        };
        setRows(
          data
            .map((item) => {
              return {
                providerId: item.providerId,
                providerName: item.providerName,
                collectionSummery: item.collectionSummery,
                rowTotal: getSum(item.collectionSummery),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setFullMonths(data[0].collectionSummery.map((item) => item.month));
        console.log("data", data);
        setHalfRows(
          data
            .map((item) => {
              return {
                providerId: item.providerId,
                providerName: item.providerName,
                collectionSummery: item.collectionSummery.slice(6, 12),
                rowTotal: getSum(item.collectionSummery.slice(6, 12)),
                // collectionSummery: item.collectionSummery.slice(2, 5),
                // rowTotal: getSum(item.collectionSummery.slice(2, 5)),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        let flatArray = data.map((item) => item.collectionSummery).flat();
        let totalJan = 0;
        let totalFeb = 0;
        let totalMar = 0;
        let totalApr = 0;
        let totalMay = 0;
        let totalJun = 0;
        let totalJul = 0;
        let totalAug = 0;
        let totalSep = 0;
        let totalOct = 0;
        let totalNov = 0;
        let totalDec = 0;
        const getTotal = (month) => {
          let newArray = flatArray
            .filter((item) => (item.month === month ? item : null))
            .map((item) =>
              item
                ? {
                    collection: item.collection,
                    year: item.year,
                    month: item.month,
                  }
                : null
            );
          let totalCollection = newArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          if (newArray.length) {
            return {
              collection: totalCollection,
              month: month,
              year: newArray[0].year,
            };
          } else {
            return null;
          }
        };
        totalJan = getTotal(1);
        totalFeb = getTotal(2);
        totalMar = getTotal(3);
        totalApr = getTotal(4);
        totalMay = getTotal(5);
        totalJun = getTotal(6);
        totalJul = getTotal(7);
        totalAug = getTotal(8);
        totalSep = getTotal(9);
        totalOct = getTotal(10);
        totalNov = getTotal(11);
        totalDec = getTotal(12);
        let totalsArray = [
          totalJan ? totalJan : null,
          totalFeb ? totalFeb : null,
          totalMar ? totalMar : null,
          totalApr ? totalApr : null,
          totalMay ? totalMay : null,
          totalJun ? totalJun : null,
          totalJul ? totalJul : null,
          totalAug ? totalAug : null,
          totalSep ? totalSep : null,
          totalOct ? totalOct : null,
          totalNov ? totalNov : null,
          totalDec ? totalDec : null,
        ];
        setTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.collection)
        );
        setHalfTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.collection)
            .slice(6, 12)
          // .slice(0, 6)
        );
        let chartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.providerName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.collectionSummery.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.collection };
            }),
          };
          return chartObj;
        });
        setLineData(chartArray);
        setHalfLineData(
          chartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );
        let jan = { month: "Jan" };
        let feb = { month: "Feb" };
        let mar = { month: "Mar" };
        let apr = { month: "Apr" };
        let may = { month: "May" };
        let jun = { month: "Jun" };
        let jul = { month: "Jul" };
        let aug = { month: "Aug" };
        let sep = { month: "Sep" };
        let oct = { month: "Oct" };
        let nov = { month: "Nov" };
        let dec = { month: "Dec" };
        data.forEach((item) => {
          item.collectionSummery.forEach((x, i) => {
            if (x.month === 1) {
              jan[item.providerName] = x.collection;
              jan[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jan["id"] = i;
            }
            if (x.month === 2) {
              feb[item.providerName] = x.collection;
              feb[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              feb["id"] = i;
            }
            if (x.month === 3) {
              mar[item.providerName] = x.collection;
              mar[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              mar["id"] = i;
            }
            if (x.month === 4) {
              apr[item.providerName] = x.collection;
              apr[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              apr["id"] = i;
            }
            if (x.month === 5) {
              may[item.providerName] = x.collection;
              may[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              may["id"] = i;
            }
            if (x.month === 6) {
              jun[item.providerName] = x.collection;
              jun[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jun["id"] = i;
            }
            if (x.month === 7) {
              jul[item.providerName] = x.collection;
              jul[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jul["id"] = i;
            }
            if (x.month === 8) {
              aug[item.providerName] = x.collection;
              aug[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              aug["id"] = i;
            }
            if (x.month === 9) {
              sep[item.providerName] = x.collection;
              sep[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              sep["id"] = i;
            }
            if (x.month === 10) {
              oct[item.providerName] = x.collection;
              oct[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              oct["id"] = i;
            }
            if (x.month === 11) {
              nov[item.providerName] = x.collection;
              nov[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              nov["id"] = i;
            }
            if (x.month === 12) {
              dec[item.providerName] = x.collection;
              dec[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              dec["id"] = i;
            }
          });
        });

        let graphData = [
          jan,
          feb,
          mar,
          apr,
          may,
          jun,
          jul,
          aug,
          sep,
          oct,
          nov,
          dec,
        ].sort((a, b) => a.id - b.id);
        console.log(graphData);
        setChartData(graphData);
        setHalfChartData(graphData.slice(6, 12));
        // setHalfChartData(graphData.slice(0, 6));
        // setHalfChartData([apr, may, jun, jul, aug, sep]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.colData.ColGroupByProvider.response]);

  useEffect(() => {
    if (
      props.colData &&
      props.colData.ColGroupByFacility &&
      props.colData.ColGroupByFacility.response
    ) {
      console.log(props.colData.ColGroupByFacility);
      if (props.colData.ColGroupByFacility.response.responseCode === 0) {
        let data = props.colData.ColGroupByFacility.response.data;
        // data.sort(function (a, b) {
        //   return parseInt(a.month) - parseInt(b.month);
        // });
        // data.sort(function (a, b) {
        //   return parseInt(a.year) - parseInt(b.year);
        // });
        const getSum = (rowArray) => {
          let sum = rowArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          return sum;
        };
        setRows(
          data
            .map((item) => {
              return {
                facilityId: item.facilityId,
                facilityName: item.facilityName,
                collectionSummery: item.collectionSummery,
                rowTotal: getSum(item.collectionSummery),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setFullMonths(data[0].collectionSummery.map((item) => item.month));

        setHalfRows(
          data
            .map((item) => {
              return {
                facilityId: item.facilityId,
                facilityName: item.facilityName,
                collectionSummery: item.collectionSummery.slice(6, 12),
                rowTotal: getSum(item.collectionSummery.slice(6, 12)),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        let flatArray = data.map((item) => item.collectionSummery).flat();
        let totalJan = 0;
        let totalFeb = 0;
        let totalMar = 0;
        let totalApr = 0;
        let totalMay = 0;
        let totalJun = 0;
        let totalJul = 0;
        let totalAug = 0;
        let totalSep = 0;
        let totalOct = 0;
        let totalNov = 0;
        let totalDec = 0;
        const getTotal = (month) => {
          let newArray = flatArray
            .filter((item) => (item.month === month ? item : null))
            .map((item) =>
              item
                ? {
                    collection: item.collection,
                    year: item.year,
                    month: item.month,
                  }
                : null
            );
          let totalcollection = newArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          if (newArray.length) {
            return {
              collection: totalcollection,
              month: month,
              year: newArray[0].year,
            };
          } else {
            return null;
          }
        };
        totalJan = getTotal(1);
        totalFeb = getTotal(2);
        totalMar = getTotal(3);
        totalApr = getTotal(4);
        totalMay = getTotal(5);
        totalJun = getTotal(6);
        totalJul = getTotal(7);
        totalAug = getTotal(8);
        totalSep = getTotal(9);
        totalOct = getTotal(10);
        totalNov = getTotal(11);
        totalDec = getTotal(12);
        let totalsArray = [
          totalJan ? totalJan : null,
          totalFeb ? totalFeb : null,
          totalMar ? totalMar : null,
          totalApr ? totalApr : null,
          totalMay ? totalMay : null,
          totalJun ? totalJun : null,
          totalJul ? totalJul : null,
          totalAug ? totalAug : null,
          totalSep ? totalSep : null,
          totalOct ? totalOct : null,
          totalNov ? totalNov : null,
          totalDec ? totalDec : null,
        ];
        setTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.collection)
        );
        setHalfTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.collection)
            .slice(6, 12)
        );
        let chartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.facilityName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.collectionSummery.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.collection };
            }),
          };
          return chartObj;
        });
        setLineData(chartArray);
        setHalfLineData(
          chartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );
        let jan = { month: "Jan" };
        let feb = { month: "Feb" };
        let mar = { month: "Mar" };
        let apr = { month: "Apr" };
        let may = { month: "May" };
        let jun = { month: "Jun" };
        let jul = { month: "Jul" };
        let aug = { month: "Aug" };
        let sep = { month: "Sep" };
        let oct = { month: "Oct" };
        let nov = { month: "Nov" };
        let dec = { month: "Dec" };
        data.forEach((item) => {
          item.collectionSummery.forEach((x, i) => {
            if (x.month === 1) {
              jan[item.facilityName] = x.collection;
              jan[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              jan["id"] = i;
            }
            if (x.month === 2) {
              feb[item.facilityName] = x.collection;
              feb[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              feb["id"] = i;
            }
            if (x.month === 3) {
              mar[item.facilityName] = x.collection;
              mar[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              mar["id"] = i;
            }
            if (x.month === 4) {
              apr[item.facilityName] = x.collection;
              apr[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              apr["id"] = i;
            }
            if (x.month === 5) {
              may[item.facilityName] = x.collection;
              may[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              may["id"] = i;
            }
            if (x.month === 6) {
              jun[item.facilityName] = x.collection;
              jun[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              jun["id"] = i;
            }
            if (x.month === 7) {
              jul[item.facilityName] = x.collection;
              jul[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jul["id"] = i;
            }
            if (x.month === 8) {
              aug[item.facilityName] = x.collection;
              aug[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              aug["id"] = i;
            }
            if (x.month === 9) {
              sep[item.facilityName] = x.collection;
              sep[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              sep["id"] = i;
            }
            if (x.month === 10) {
              oct[item.facilityName] = x.collection;
              oct[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              oct["id"] = i;
            }
            if (x.month === 11) {
              nov[item.facilityName] = x.collection;
              nov[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              nov["id"] = i;
            }
            if (x.month === 12) {
              dec[item.facilityName] = x.collection;
              dec[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
              dec["id"] = i;
            }
          });
        });
        let graphData = [
          jan,
          feb,
          mar,
          apr,
          may,
          jun,
          jul,
          aug,
          sep,
          oct,
          nov,
          dec,
        ].sort((a, b) => a.id - b.id);
        setChartData(graphData);
        setHalfChartData(graphData.slice(6, 12));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.colData.ColGroupByFacility.response]);

  useEffect(() => {
    if (
      props.colData &&
      props.colData.ColGroupByService &&
      props.colData.ColGroupByService.response
    ) {
      if (props.colData.ColGroupByService.response.responseCode === 0) {
        let data = props.colData.ColGroupByService.response.data;
        const getSum = (rowArray) => {
          let sum = rowArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          return sum;
        };
        setRows(
          data
            .map((item) => {
              return {
                serviceId: item.serviceId,
                serviceName: item.serviceName,
                collectionSummery: item.collectionSummery,
                rowTotal: getSum(item.collectionSummery),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setFullMonths(data[0].collectionSummery.map((item) => item.month));

        setHalfRows(
          data
            .map((item) => {
              return {
                serviceId: item.serviceId,
                serviceName: item.serviceName,
                collectionSummery: item.collectionSummery.slice(6, 12),
                rowTotal: getSum(item.collectionSummery.slice(6, 12)),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        let flatArray = data.map((item) => item.collectionSummery).flat();
        let totalJan = 0;
        let totalFeb = 0;
        let totalMar = 0;
        let totalApr = 0;
        let totalMay = 0;
        let totalJun = 0;
        let totalJul = 0;
        let totalAug = 0;
        let totalSep = 0;
        let totalOct = 0;
        let totalNov = 0;
        let totalDec = 0;
        const getTotal = (month) => {
          let newArray = flatArray
            .filter((item) => (item.month === month ? item : null))
            .map((item) =>
              item
                ? {
                    collection: item.collection,
                    year: item.year,
                    month: item.month,
                  }
                : null
            );
          let totalCollection = newArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          if (newArray.length) {
            return {
              collection: totalCollection,
              month: month,
              year: newArray[0].year,
            };
          } else {
            return null;
          }
        };
        totalJan = getTotal(1);
        totalFeb = getTotal(2);
        totalMar = getTotal(3);
        totalApr = getTotal(4);
        totalMay = getTotal(5);
        totalJun = getTotal(6);
        totalJul = getTotal(7);
        totalAug = getTotal(8);
        totalSep = getTotal(9);
        totalOct = getTotal(10);
        totalNov = getTotal(11);
        totalDec = getTotal(12);
        let totalsArray = [
          totalJan ? totalJan : null,
          totalFeb ? totalFeb : null,
          totalMar ? totalMar : null,
          totalApr ? totalApr : null,
          totalMay ? totalMay : null,
          totalJun ? totalJun : null,
          totalJul ? totalJul : null,
          totalAug ? totalAug : null,
          totalSep ? totalSep : null,
          totalOct ? totalOct : null,
          totalNov ? totalNov : null,
          totalDec ? totalDec : null,
        ];
        setTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.collection)
        );
        setHalfTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.collection)
            .slice(6, 12)
        );

        let chartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.serviceName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.collectionSummery.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.collection };
            }),
          };
          return chartObj;
        });
        setLineData(chartArray);
        setHalfLineData(
          chartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );

        let jan = { month: "Jan" };
        let feb = { month: "Feb" };
        let mar = { month: "Mar" };
        let apr = { month: "Apr" };
        let may = { month: "May" };
        let jun = { month: "Jun" };
        let jul = { month: "Jul" };
        let aug = { month: "Aug" };
        let sep = { month: "Sep" };
        let oct = { month: "Oct" };
        let nov = { month: "Nov" };
        let dec = { month: "Dec" };
        data.forEach((item) => {
          item.collectionSummery.forEach((x, i) => {
            if (x.month === 1) {
              jan[item.serviceName] = x.collection;
              jan[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jan["id"] = i;
            }
            if (x.month === 2) {
              feb[item.serviceName] = x.collection;
              feb[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              feb["id"] = i;
            }
            if (x.month === 3) {
              mar[item.serviceName] = x.collection;
              mar[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              mar["id"] = i;
            }
            if (x.month === 4) {
              apr[item.serviceName] = x.collection;
              apr[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              apr["id"] = i;
            }
            if (x.month === 5) {
              may[item.serviceName] = x.collection;
              may[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              may["id"] = i;
            }
            if (x.month === 6) {
              jun[item.serviceName] = x.collection;
              jun[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jun["id"] = i;
            }
            if (x.month === 7) {
              jul[item.serviceName] = x.collection;
              jul[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jul["id"] = i;
            }
            if (x.month === 8) {
              aug[item.serviceName] = x.collection;
              aug[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              aug["id"] = i;
            }
            if (x.month === 9) {
              sep[item.serviceName] = x.collection;
              sep[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              sep["id"] = i;
            }
            if (x.month === 10) {
              oct[item.serviceName] = x.collection;
              oct[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              oct["id"] = i;
            }
            if (x.month === 11) {
              nov[item.serviceName] = x.collection;
              nov[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              nov["id"] = i;
            }
            if (x.month === 12) {
              dec[item.serviceName] = x.collection;
              dec[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              dec["id"] = i;
            }
          });
        });

        let graphData = [
          jan,
          feb,
          mar,
          apr,
          may,
          jun,
          jul,
          aug,
          sep,
          oct,
          nov,
          dec,
        ].sort((a, b) => a.id - b.id);
        setChartData(graphData);
        setHalfChartData(graphData.slice(6, 12));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.colData.ColGroupByService.response]);

  return (
    <div className="prodSummary_root">
      {/* <div className="heading">Collection Summary</div> */}
      <div>
        <div className="searchbar_container">
          <div className="dropdown_wrap_container">
            <FormControl className="filter_dropdown_wrap">
              <InputLabel>Group by</InputLabel>
              <Select
                className="dropdown"
                value={group}
                onChange={(e) => handleGroupChange(e)}
              >
                {groups &&
                  groups.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                {/* <MenuItem disabled={group === 1} value={1}>
                  Provider
                </MenuItem>
                <MenuItem disabled={group === 2} value={2}>
                  Facility
                </MenuItem>
                <MenuItem disabled={group === 3} value={3}>
                  Service
                </MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="dropdown_wrap_container">
            <FormControl className="filter_dropdown_wrap">
              <InputLabel>Filter By</InputLabel>
              <Select
                className="dropdown"
                value={filter}
                onChange={(e) => handleFilterChange(e)}
              >
                {/* {filters &&
                  filters.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))} */}
                <MenuItem disabled={group === 1} value={1}>
                  Provider
                </MenuItem>
                <MenuItem disabled={group === 2} value={2}>
                  Facility
                </MenuItem>
                <MenuItem disabled={group === 3} value={3}>
                  Service
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          {filter === 2 ? (
            <div className="dropdown_wrap_container">
              <FormControl className="filter_dropdown_wrap">
                <InputLabel>Facility Types</InputLabel>
                <Select
                  className="dropdown"
                  value={selectedFacility}
                  // disabled={service !== ""}
                  onChange={(e) => handleFacilityChange(e)}
                >
                  {facilityTypes &&
                    facilityTypes.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  {/* {facilities &&
                    facilities.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))} */}
                </Select>
              </FormControl>
            </div>
          ) : null}
          {filter === 3 ? (
            <div className="dropdown_wrap_container">
              <FormControl className="filter_dropdown_wrap">
                <InputLabel>Service</InputLabel>
                <Select
                  className="dropdown"
                  value={service}
                  // disabled={selectedFacility !== ""}
                  onChange={(e) => handleServiceChange(e)}
                >
                  {services &&
                    services.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          ) : null}
          {filter === 1 ? (
            <div className="dropdown_wrap_container">
              <FormControl className="filter_dropdown_wrap">
                <InputLabel>Provider</InputLabel>
                <Select
                  className="dropdown"
                  value={provider}
                  // disabled={service !== ""}
                  onChange={(e) => handleProviderChange(e)}
                >
                  {providers &&
                    providers.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          ) : null}

          <div className="dropdown_wrap_container">
            <FormControl className="filter_dropdown_wrap">
              <InputLabel>Period</InputLabel>
              <Select value={period} onChange={(e) => handlePeriodChange(e)}>
                {periods &&
                  periods.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="Btn_wrap">
            <div className="filterBtn_wrap">
              <Button
                className="filterBtn"
                variant="contained"
                color="primary"
                onClick={() => handleFilter()}
              >
                Filter
              </Button>
            </div>
            {/* <div className="filterBtn_wrap">
              <Button
                className="downloadBtn"
                variant="outlined"
                color="primary"
                onClick={() => handleGraphDownload()}
              >
                Print
              </Button>
            </div> */}
          </div>
        </div>
        <div className="graph_container">
          <div className="graph_card">
            <div className="graph_heading">
              {/* {values.group === 1
                ? values.service === -1
                  ? "Total Collection"
                  : services.filter((item) => item.value === values.service)[0]
                      .name
                : values.group === 2
                ? values.provider === -1
                  ? "Total Collection"
                  : providers.filter(
                      (item) => item.value === values.provider
                    )[0].name
                : ""} */}
              {filter === 1
                ? values.provider === "" || values.provider === -1
                  ? "Total Collection"
                  : providers.filter(
                      (item) => item.value === values.provider
                    )[0].name
                : filter === 2
                ? values.facility === "" || values.facility === -1
                  ? "Total Collection"
                  : facilityTypes.filter(
                      (item) => item.value === values.facility
                    )[0].name
                : filter === 3
                ? values.service === "" || values.service === -1
                  ? "Total Collection"
                  : services.filter((item) => item.value === values.service)[0]
                      .name
                : "null"}
              {/* Total Collection */}
              <Button
                onClick={handleGraphDownload}
                variant="outlined"
                color="primary"
                size="small"
              >
                <GetApp />
              </Button>
            </div>
            <div id="graph" className="graph_content">
              <LineGraph
                period={values.period}
                // group={group}
                chartData={lineData}
                halfChartData={halfLineData}
              />
              {/* <BarGroup
                period={values.period}
                group={values.group}
                // keys={group === 1 ? chartProviders : chartServices}
                providerNames={chartProviders}
                facilityNames={chartFacilities}
                serviceNames={chartServices}
                chartData={chartData}
                halfChartData={halfChartData}
              /> */}
            </div>
          </div>
        </div>
        <div className="table_container">
          <div className="appsTab__container">
            <TableContainer component={Paper} elevation={0}>
              {/* group By Provider */}
              {values.group === 1 ? (
                <Table aria-label="sticky table">
                  <TableHead>
                    {values.period === 1 ? (
                      <TableRow>
                        <TableCell className="col_table_head_cell col_provider_name">
                          <TableSortLabel>
                            {/* {values.service === -1
                              ? "Total"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name} */}
                            {values.facility !== ""
                              ? values.facility === -1
                                ? ""
                                : facilityTypes.filter(
                                    (item) => item.value === values.facility
                                  )[0].name
                              : values.service !== ""
                              ? values.service === -1
                                ? "Total"
                                : services.filter(
                                    (item) => item.value === values.service
                                  )[0].name
                              : ""}
                          </TableSortLabel>
                        </TableCell>
                        {/* {halfMonths.map((item, i) => (
                          <TableCell key={i} className="col_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                        {halfRows.length > 0
                          ? halfRows[0].collectionSummery.map((item, i) => (
                              <TableCell
                                key={i}
                                align="center"
                                className="col_table_head_cell"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            ))
                          : fullMonths?.slice(6, 12).map((item) => (
                              <TableCell
                                className="col_table_head_cell month_name"
                                align="center"
                              >
                                {months[item - 1]}
                              </TableCell>
                            ))}
                      </TableRow>
                    ) : values.period === 2 ? (
                      <TableRow>
                        <TableCell className="col_table_head_cell col_provider_name">
                          <TableSortLabel>
                            {/* {values.service === -1
                              ? "Total"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name} */}
                            {values.facility !== ""
                              ? values.facility === -1
                                ? ""
                                : facilityTypes.filter(
                                    (item) => item.value === values.facility
                                  )[0].name
                              : values.service !== ""
                              ? values.service === -1
                                ? "Total"
                                : services.filter(
                                    (item) => item.value === values.service
                                  )[0].name
                              : ""}
                          </TableSortLabel>
                        </TableCell>
                        {/* {months.map((item, i) => (
                          <TableCell key={i} className="col_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                        {rows.length > 0
                          ? rows[0].collectionSummery.map((item, i) => (
                              <TableCell
                                key={i}
                                align="center"
                                className="col_table_head_cell"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            ))
                          : fullMonths?.map((item) => (
                              <TableCell
                                className="col_table_head_cell month_name"
                                align="center"
                              >
                                {months[item - 1]}
                              </TableCell>
                            ))}
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableHead>

                  {values.period === 1 ? (
                    <TableBody>
                      {halfRows
                        ? halfRows.map((item, i) => (
                            <TableRow key={i}>
                              <TableCell className="col_provider_name">
                                {item.providerName}
                              </TableCell>
                              {item.collectionSummery &&
                                item.collectionSummery.map((element) => (
                                  <TableCell
                                    className="collection_sub_cell"
                                    key={element.collectionId}
                                    align="center"
                                  >
                                    $
                                    {element.collection
                                      ? parseFloat(
                                          element.collection,
                                          10
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                        })
                                      : "0"}
                                  </TableCell>
                                ))}
                            </TableRow>
                          ))
                        : ""}

                      <TableRow>
                        <TableCell className="col_totals_name_column1">
                          TOTAL
                        </TableCell>
                        {halfTotals.map((item, i) => (
                          <TableCell
                            align="center"
                            key={i}
                            className="totals_name"
                          >
                            $
                            {item
                              ? parseFloat(item, 10).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })
                              : "0"}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : values.period === 2 ? (
                    <TableBody>
                      {rows &&
                        rows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="col_provider_name">
                              {item.providerName}
                            </TableCell>
                            {item.collectionSummery &&
                              item.collectionSummery.map((element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  $
                                  {element.collection
                                    ? parseFloat(
                                        element.collection,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                      })
                                    : "0"}
                                </TableCell>
                              ))}
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell className="col_totals_name_column1">
                          TOTAL
                        </TableCell>
                        {totals.map((item, i) => (
                          <TableCell
                            align="center"
                            key={i}
                            className="totals_name"
                          >
                            $
                            {item
                              ? parseFloat(item, 10).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })
                              : "0"}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : (
                    ""
                  )}
                </Table>
              ) : //group by facility
              values.group === 2 ? (
                <Table aria-label="sticky table">
                  {/* Header 6 months  */}
                  <TableHead>
                    {values.period === 1 ? (
                      <TableRow>
                        <TableCell className="col_table_head_cell col_provider_name">
                          <TableSortLabel>
                            {/* {values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name} */}
                            {filter === 1
                              ? values.provider === -1
                                ? "Total"
                                : providers.filter(
                                    (item) => item.value === values.provider
                                  )[0].name
                              : filter === 3
                              ? values.service === -1
                                ? "Total"
                                : services.filter(
                                    (item) => item.value === values.service
                                  )[0].name
                              : "Total"}
                          </TableSortLabel>
                        </TableCell>
                        {/* {halfMonths.map((item, i) => (
                          <TableCell key={i} className="col_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                        {halfRows.length > 0
                          ? halfRows[0].collectionSummery.map((item, i) => (
                              <TableCell
                                key={i}
                                className="col_table_head_cell"
                                align="center"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            ))
                          : fullMonths?.slice(6, 12).map((item) => (
                              <TableCell
                                className="col_table_head_cell month_name"
                                align="center"
                              >
                                {months[item - 1]}
                              </TableCell>
                            ))}
                      </TableRow>
                    ) : values.period === 2 ? (
                      <TableRow>
                        <TableCell className="col_table_head_cell col_provider_name">
                          <TableSortLabel>
                            {/* {values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name} */}
                            {filter === 1
                              ? values.provider === -1
                                ? "Total"
                                : providers.filter(
                                    (item) => item.value === values.provider
                                  )[0].name
                              : filter === 3
                              ? values.service === -1
                                ? "Total"
                                : services.filter(
                                    (item) => item.value === values.service
                                  )[0].name
                              : "Total"}
                          </TableSortLabel>
                        </TableCell>
                        {/* {months.map((item, i) => (
                          <TableCell key={i} className="col_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                        {rows.length > 0
                          ? rows[0].collectionSummery.map((item, i) => (
                              <TableCell
                                key={i}
                                align="center"
                                className="col_table_head_cell"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            ))
                          : fullMonths?.map((item) => (
                              <TableCell
                                className="col_table_head_cell month_name"
                                align="center"
                              >
                                {months[item - 1]}
                              </TableCell>
                            ))}
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableHead>

                  {values.period === 1 ? (
                    <TableBody>
                      {halfRows &&
                        halfRows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="col_provider_name">
                              {item.facilityName}
                            </TableCell>
                            {item.collectionSummery &&
                              item.collectionSummery.map((element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  $
                                  {element.collection
                                    ? parseFloat(
                                        element.collection,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                      })
                                    : "0"}
                                </TableCell>
                              ))}
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell className="col_totals_name_column1">
                          TOTAL
                        </TableCell>
                        {halfTotals.map((item, i) => (
                          <TableCell
                            align="center"
                            key={i}
                            className="totals_name"
                          >
                            $
                            {item
                              ? parseFloat(item, 10).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })
                              : "0"}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : values.period === 2 ? (
                    <TableBody>
                      {rows &&
                        rows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="col_provider_name">
                              {item.facilityName}
                            </TableCell>
                            {item.collectionSummery &&
                              item.collectionSummery.map((element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  $
                                  {element.collection
                                    ? parseFloat(
                                        element.collection,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                      })
                                    : "0"}
                                </TableCell>
                              ))}
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell className="col_totals_name_column1">
                          TOTAL
                        </TableCell>
                        {totals.map((item, i) => (
                          <TableCell
                            align="center"
                            key={i}
                            className="totals_name"
                          >
                            $
                            {item
                              ? parseFloat(item, 10).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })
                              : "0"}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : (
                    ""
                  )}
                </Table>
              ) : (
                //group by service
                <Table aria-label="sticky table">
                  <TableHead>
                    {values.period === 1 ? (
                      <TableRow>
                        <TableCell className="col_table_head_cell col_provider_name">
                          <TableSortLabel>
                            {/* {values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name} */}
                            {filter === 1
                              ? values.provider === -1
                                ? "Total"
                                : providers.filter(
                                    (item) => item.value === values.provider
                                  )[0].name
                              : filter === 2
                              ? values.facility === -1
                                ? "Total"
                                : facilityTypes.filter(
                                    (item) => item.value === values.facility
                                  )[0].name
                              : "Total"}
                          </TableSortLabel>
                        </TableCell>
                        {/* {halfMonths.map((item, i) => (
                          <TableCell key={i} className="col_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                        {halfRows.length > 0
                          ? halfRows[0].collectionSummery.map((item, i) => (
                              <TableCell
                                key={i}
                                className="col_table_head_cell"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            ))
                          : fullMonths?.slice(6, 12).map((item) => (
                              <TableCell
                                className="col_table_head_cell month_name"
                                align="center"
                              >
                                {months[item - 1]}
                              </TableCell>
                            ))}
                      </TableRow>
                    ) : values.period === 2 ? (
                      <TableRow>
                        <TableCell className="col_table_head_cell col_provider_name">
                          <TableSortLabel>
                            {/* {values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name} */}
                            {filter === 1
                              ? values.provider === -1
                                ? "Total"
                                : providers.filter(
                                    (item) => item.value === values.provider
                                  )[0].name
                              : filter === 2
                              ? values.facility === -1
                                ? "Total"
                                : facilityTypes.filter(
                                    (item) => item.value === values.facility
                                  )[0].name
                              : "Total"}
                          </TableSortLabel>
                        </TableCell>
                        {/* {months.map((item, i) => (
                          <TableCell key={i} className="col_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                        {rows.length > 0
                          ? rows[0].collectionSummery.map((item, i) => (
                              <TableCell
                                key={i}
                                className="col_table_head_cell"
                                align="center"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            ))
                          : fullMonths?.map((item) => (
                              <TableCell
                                className="col_table_head_cell month_name"
                                align="center"
                              >
                                {months[item - 1]}
                              </TableCell>
                            ))}
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableHead>

                  {values.period === 1 ? (
                    <TableBody>
                      {halfRows &&
                        halfRows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="col_provider_name">
                              {item.serviceName}
                            </TableCell>
                            {item.collectionSummery &&
                              item.collectionSummery.map((element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  $
                                  {element.collection
                                    ? parseFloat(
                                        element.collection,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                      })
                                    : "0"}
                                </TableCell>
                              ))}
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell className="col_totals_name_column1">
                          TOTAL
                        </TableCell>
                        {halfTotals.map((item, i) => (
                          <TableCell
                            align="center"
                            key={i}
                            className="totals_name"
                          >
                            $
                            {item
                              ? parseFloat(item, 10).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })
                              : "0"}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : values.period === 2 ? (
                    <TableBody>
                      {rows &&
                        rows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="col_provider_name">
                              {item.serviceName}
                            </TableCell>
                            {item.collectionSummery &&
                              item.collectionSummery.map((element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  $
                                  {element.collection
                                    ? parseFloat(
                                        element.collection,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                      })
                                    : "0"}
                                </TableCell>
                              ))}
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell className="col_totals_name_column1">
                          TOTAL
                        </TableCell>
                        {totals.map((item, i) => (
                          <TableCell
                            align="center"
                            key={i}
                            className="totals_name"
                          >
                            $
                            {item
                              ? parseFloat(item, 10).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })
                              : "0"}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : (
                    ""
                  )}
                </Table>
              )}
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
  colData: state.collection,
});

const mapDispatchToProps = (dispatch) => ({
  getProvidersList: (values) => dispatch(getProvidersList(values)),
  getServicesList: (values) => dispatch(getServicesList(values)),
  getFacilitiesList: (values) => dispatch(getFacilitiesList(values)),
  getColGroupByProvider: (values) => dispatch(getColGroupByProvider(values)),
  getColGroupByFacility: (values) => dispatch(getColGroupByFacility(values)),
  getColGroupByService: (values) => dispatch(getColGroupByService(values)),
});
Collection.propTypes = {
  dashboardData: PropTypes.object,
  colData: PropTypes.object,
  getProvidersList: PropTypes.func,
  getServicesList: PropTypes.func,
  getColGroupByProvider: PropTypes.func,
  getColGroupByFacility: PropTypes.func,
  getColGroupByService: PropTypes.func,
  getFacilitiesList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
