import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
import { ajax as UtilsAjax } from "Utils";
import environment from "../../environments/environment";

import { REQUEST_CANCEL, GET_PAYORS_LIST, EFT_UPDATE_STATUS } from "./PayorMix.types"

import { getPayorsListRes, eftUpdatedStatusRes } from "./PayorMix.actions"

const baseUrl = environment.baseUrl;
const PayorMixEpic = {};

PayorMixEpic.getPayorsList = (action$) =>
    action$.pipe(
        ofType(GET_PAYORS_LIST),
        switchMap((action) =>
            UtilsAjax({
                url: `${baseUrl}/dashboard-service-api/v1/EftTab/?clinicId=${action.payload.clinicId}&offset=${action.payload.offset}&order=${action.payload.order}&sortId=${action.payload.sortId}`,
                // url: `${baseUrl}/dashboard-service-api/v1/EftTab/?clinicId=${action.payload.clinicId}&offset=${action.payload.offset}`,
                method: "GET",
            }).pipe(
                map(
                    (response) => getPayorsListRes(response),
                    takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
                )
            )
        )
    );

PayorMixEpic.eftUpdatedStatus = (action$) =>
    action$.pipe(
        ofType(EFT_UPDATE_STATUS),
        switchMap((action) =>
            UtilsAjax({
                url: `${baseUrl}/dashboard-service-api/v1/EftTab/${action.payload.clinicId}?bussinessId=${action.payload.bussinessId}&eftStatus=${action.payload.eftStatus}&eraStatus=${action.payload.eraStatus}&networkStatus=${action.payload.networkStatus}`,
                method: "PUT",
            }).pipe(
                map(
                    (response) => eftUpdatedStatusRes(response),
                    takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
                )
            )
        )
    );

export default PayorMixEpic;
