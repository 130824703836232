import {
  PROD_GROUP_BY_PROVIDER_RES,
  PROD_GROUP_BY_SERVICE_RES,
  PROD_GROUP_BY_FACILITY_RES,
  PRODUCTION_DETAILED_RES,
  CLAIM_LEVEL_RES,
  NOTES_RES,
  EXPORT_DETAILS_RES,
  DOWNLOAD_DETAILS_RES,
  GET_EXPORT_STATUS_RES,
  UPDATE_APMT_TYPE_RES,
  DENIAL_CATEGORY_RES,
  EOB_RES,
  CLAIM_DOWNLOAD_RES,
  PAYORS_LIST_RES
} from "./Production.types";

/**
 * @fileOverview Manages the response of action
 * @author  Merina Joy <merina@tensaw.email>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  ProdGroupByProvider: [],
  ProdGroupByService: [],
  ProdGroupByFacility: [],
  prodDetailed: {},
  claimLevel: {},
  notes: {},
  exportResponse: {},
  downloadResponse: {},
  exportStatus: {},
  updateApmtResponse: {},
  denialCategory: {},
  claimFile: null,
  eob: {},
  payorsList: [],
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const ProductionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROD_GROUP_BY_PROVIDER_RES: {
      return { ...state, ...{ ProdGroupByProvider: action.payload } };
    }
    case PROD_GROUP_BY_SERVICE_RES: {
      return { ...state, ...{ ProdGroupByService: action.payload } };
    }
    case PROD_GROUP_BY_FACILITY_RES: {
      return { ...state, ...{ ProdGroupByFacility: action.payload } };
    }
    case PRODUCTION_DETAILED_RES: {
      return { ...state, ...{ prodDetailed: action.payload } };
    }
    case CLAIM_LEVEL_RES: {
      return { ...state, ...{ claimLevel: action.payload } };
    }
    case NOTES_RES: {
      return { ...state, ...{ notes: action.payload } };
    }
    case EXPORT_DETAILS_RES: {
      return { ...state, ...{ exportResponse: action.payload } };
    }
    case DOWNLOAD_DETAILS_RES: {
      return { ...state, ...{ downloadResponse: action.payload } };
    }
    case GET_EXPORT_STATUS_RES: {
      return { ...state, ...{ exportStatus: action.payload } };
    }
    case UPDATE_APMT_TYPE_RES: {
      return { ...state, ...{ updateApmtResponse: action.payload } };
    }
    case DENIAL_CATEGORY_RES: {
      return { ...state, ...{ denialCategory: action.payload } };
    }
    case EOB_RES: {
      return { ...state, ...{ eob: action.payload } };
    }
    case CLAIM_DOWNLOAD_RES: {
      return {
        ...state,
        ...{ claimFile: action.payload },
      };
    }
    case PAYORS_LIST_RES: {
      return { ...state, ...{ payorsList: action.payload } }
    }
    default:
      return state;
  }
};

export default ProductionReducer;
