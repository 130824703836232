import React, { useState } from "react";
import { createContext } from "react";

export const denialCategoryContext = createContext();

const DenialReportContext = ({ children }) => {
  //   const [values, setValues] = useState();
  const [service, setService] = useState(-1);
  const [period, setPeriod] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [grandTotal, setGrandTotal] = useState({});

  // const [claimId, setClaimId] = useState("")
  const [claims, setClaims] = useState([]);
  const [icds, setIcds] = useState({});
  const [values, setValues] = useState({
    period: 1,
    provider: -1,
    service: -1,
    startDate: "",
    endDate: "",
  });
  const [provider, setProvider] = useState(-1);

  return (
    <denialCategoryContext.Provider
      value={{
        service,
        setService,
        period,
        setPeriod,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        rows,
        setRows,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalCount,
        setTotalCount,
        grandTotal,
        setGrandTotal,
        claims,
        setClaims,
        icds,
        setIcds,
        values,
        setValues,
        provider,
        setProvider,
      }}
    >
      {children}
    </denialCategoryContext.Provider>
  );
};

export default DenialReportContext;
