import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
// import ArrowDownIcon from "@material-ui/icons/ExpandMoreRounded";
import {
  getClinicDetails,
  getProvidersList,
  getFacilitiesList,
  getProductionTrend,
  getCollectionTrend,
  getForecast,
  getNotFiledClaims,
  getReceivableNet,
  getReceivableGross,
} from "Redux/Dashboard/Dashboard.actions";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import "./style.css";
import { dashboardContext } from "context/DashboardContextProvider";
import { GetApp } from "@material-ui/icons";
import html2canvas from "html2canvas";

function Dashboard(props) {
  const clinic_id = localStorage.getItem("clinic_id");
  // const [clinicData, setClinicData] = useState({})
  const [providers, setProviders] = useState([
    {
      name: "All providers",
      value: -1,
    },
  ]);
  // const [provider, setProvider] = useState(-1);
  const { provider, setProvider } = useContext(dashboardContext);
  const [productionData, setProductionData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  // const [forecastData, setForecastData] = useState([]);
  const [notFiledData, setNotFiledData] = useState([]);
  const [netData, setNetData] = useState([]);
  const [grossData, setGrossData] = useState([]);

  function numFormatter(num) {
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };

  useEffect(() => {
    props.getClinicDetails(clinic_id);
    props.getProvidersList(clinic_id);
    props.getFacilitiesList(clinic_id);
    props.getProductionTrend({
      clinicId: clinic_id,
      providerId: provider === -1 ? -1 : provider,
    });
    props.getCollectionTrend({
      clinicId: clinic_id,
      providerId: provider === -1 ? -1 : provider,
    });
    props.getNotFiledClaims(clinic_id);
    props.getReceivableNet(clinic_id);
    props.getReceivableGross(clinic_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.clinicDetails &&
      props.dashboardData.clinicDetails.response
    ) {
      // let data = props.dashboardData.clinicDetails.response.data;
      // setClinicData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.clinicDetails.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.providersList &&
      props.dashboardData.providersList.response
    ) {
      if (props.dashboardData.providersList.response.responseCode === 0) {
        let data = props.dashboardData.providersList.response.data;
        let providersArray = data
          .filter((item) => item.active === 1)
          .map((item) => {
            return {
              name:
                item.firstName + " " + item.middleName + " " + item.lastName,
              value: item.id,
            };
          });
        let newArray = [
          {
            name: "All providers",
            value: -1,
          },
          ...providersArray,
        ];
        setProviders(newArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.providersList.response]);

  // useEffect(() => {
  //   if (
  //     props.dashboardData &&
  //     props.dashboardData.facilitiesList &&
  //     props.dashboardData.facilitiesList.response
  //   ) {
  //     if (props.dashboardData.facilitiesList.response.responseCode === 0) {
  //       let data = props.dashboardData.facilitiesList.response.data;
  //       let facilitiesArray = data
  //         .filter((item) => item.active === 1)
  //         .map((item) => {
  //           return {
  //             name: item.locationName,
  //             value: item.id,
  //           };
  //         });
  //       let newArray = [
  //         {
  //           name: "All facilities",
  //           value: -1,
  //         },
  //         ...facilitiesArray,
  //       ];
  //       setFacilities(newArray);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.dashboardData.facilitiesList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.productionTrend &&
      props.dashboardData.productionTrend.response
    ) {
      if (props.dashboardData.productionTrend.response.responseCode === 0) {
        let data = props.dashboardData.productionTrend.response.data;
        let productionArray = data.map((item) => {
          return {
            month: item.month,
            Amount: item.billedAmount,
            // amount: item.unApplied,
          };
        });
        setProductionData(productionArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.productionTrend.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.collectionTrend &&
      props.dashboardData.collectionTrend.response
    ) {
      if (props.dashboardData.collectionTrend.response.responseCode === 0) {
        let data = props.dashboardData.collectionTrend.response.data;
        let collectionArray = data.map((item) => {
          return {
            month: item.month,
            Amount: item.billedAmount,
            // amount: item.unApplied,
          };
        });
        setCollectionData(collectionArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.collectionTrend.response]);

  // useEffect(() => {
  //   if (
  //     props.dashboardData &&
  //     props.dashboardData.forecast &&
  //     props.dashboardData.forecast.response
  //   ) {
  //     if (props.dashboardData.forecast.response.responseCode === 0) {
  //       let data = props.dashboardData.forecast.response.data[0];
  //       let forecastArray = [
  //         { week: "1W", collection: data.wk1Collection },
  //         { week: "2W", collection: data.wk2Collection },
  //         { week: "3W", collection: data.wk3Collection },
  //         { week: "4W", collection: data.wk4Collection },
  //         { week: "5W", collection: data.wk5Collection },
  //         { week: "6W", collection: data.wk6Collection },
  //         { week: "6W+", collection: data.aboveW6Collection },
  //       ];
  //       setForecastData(forecastArray);
  //       // console.log(data);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.dashboardData.forecast.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.notFiledClaims &&
      props.dashboardData.notFiledClaims.response
    ) {
      if (props.dashboardData.notFiledClaims.response.responseCode === 0) {
        let data = props.dashboardData.notFiledClaims.response.data[0];
        let notFiledArray = [
          { week: "1W", count: data.wk1Count },
          { week: "2W", count: data.wk2Count },
          { week: "3W", count: data.wk3Count },
          { week: "4W", count: data.wk4Count },
          { week: "5W", count: data.wk5Count },
          { week: "6W", count: data.wk6Count },
          { week: "6W+", count: data.aboveWk6Count },
        ];
        setNotFiledData(notFiledArray);
        // console.log(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.notFiledClaims.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.receivableNet &&
      props.dashboardData.receivableNet.response
    ) {
      if (props.dashboardData.receivableNet.response.responseCode === 0) {
        let data = props.dashboardData.receivableNet.response.data[0];
        // console.log(data);
        if (data) {
          let netArray = [
            { arg: "30", Amount: data.net_0_30 },
            { arg: "60", Amount: data.net_31_60 },
            { arg: "90", Amount: data.net_61_90 },
            { arg: "120", Amount: data.net_91_120 },
            { arg: "120+", Amount: data.net_120_Above },
          ];
          setNetData(netArray);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.receivableNet.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.receivableGross &&
      props.dashboardData.receivableGross.response
    ) {
      if (props.dashboardData.receivableGross.response.responseCode === 0) {
        let data = props.dashboardData.receivableGross.response.data[0];
        // console.log(data);
        if (data) {
          let grossArray = [
            { arg: "30", Amount: data.gross_0_30 },
            { arg: "60", Amount: data.gross_31_60 },
            { arg: "90", Amount: data.gross_61_90 },
            { arg: "120", Amount: data.gross_91_120 },
            { arg: "120+", Amount: data.gross_120_Above },
          ];
          setGrossData(grossArray);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.receivableGross.response]);

  const handleGraphDownload = async (id) => {
    console.log(id);
    const element = document.getElementById(id),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/png"),
      link = document.createElement("a");
    link.href = data;
    link.download = "downloaded-image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFilter = () => {
    // props.getClinicDetails(clinic_id);
    // props.getProvidersList(clinic_id);
    // props.getFacilitiesList(clinic_id);
    props.getProductionTrend({
      clinicId: clinic_id,
      providerId: provider === -1 ? -1 : provider,
      // facilityId: facility === -1 ? -1 : facility,
    });
    props.getCollectionTrend({
      clinicId: clinic_id,
      providerId: provider === -1 ? -1 : provider,
      // facilityId: facility === -1 ? -1 : facility,
    });
    // props.getForecast({
    //   clinicId: clinic_id,
    //   providerId: provider === -1 ? -1 : provider,
    //   facilityId: facility === -1 ? -1 : facility,
    // });
    props.getNotFiledClaims(clinic_id);
    props.getReceivableNet(clinic_id);
    props.getReceivableGross(clinic_id);
  };

  return (
    <div className="Dashboard_root">
      {/* <div className="dashboard_heading">Dashboard</div> */}
      <div className="dashboard_searchbar_container">
        {/* <div>
          <FormControl className="dashboard_dropdown_wrap">
            <InputLabel>Filter by</InputLabel>
            <Select value={group} onChange={(e) => handleGroupChange(e)}>
              {groups &&
                groups.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div> */}

        {/* {group === 2 ? (
          <div>
            <FormControl className="dashboard_dropdown_wrap">
              <InputLabel>Facility</InputLabel>
              <Select value={facility} onChange={handleFacilityChange}>
                {facilities &&
                  facilities.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        ) : group === 1 ? (
          <div>
            <FormControl className="dashboard_dropdown_wrap">
              <InputLabel>Provider</InputLabel>
              <Select value={provider} onChange={handleProviderChange}>
                {providers &&
                  providers.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          ""
        )} */}

        <div>
          <FormControl className="dashboard_dropdown_wrap">
            <InputLabel>Provider</InputLabel>
            <Select value={provider} onChange={handleProviderChange}>
              {providers &&
                providers.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="dashboard_filterBtn_wrap">
          <Button
            className="dashboard_filterBtn"
            variant="contained"
            color="primary"
            onClick={() => handleFilter()}
          >
            Filter
          </Button>
        </div>
      </div>
      <div className="dashboard_cards_container">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper>
              <div id="prodTrend">
                <div className="card_heading">
                  <div className="graphHeading">
                    <span>Production Trend</span>
                    <IconButton
                      onClick={() => {
                        handleGraphDownload("prodTrend");
                      }}
                    >
                      <GetApp color="black" />
                    </IconButton>
                  </div>
                </div>
                <div className="card_container_big">
                  {/* <div className="card_heading">
                  <div className="graphHeading">
                    <span>Production Trend</span>
                    <IconButton>
                      <GetApp color="black" />
                    </IconButton>
                  </div>
                </div> */}
                  <div className="nivo_card_content_big">
                    <ResponsiveBar
                      data={productionData}
                      keys={["Amount"]}
                      indexBy="month"
                      gridYValues={5}
                      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                      padding={0.5}
                      valueScale={{ type: "linear" }}
                      colors="#15AFAF"
                      enableLabel={false}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: -45,
                      }}
                      axisLeft={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "$",
                        // legendPosition: "middle",
                        legendOffset: -50,
                        format: (value) => numFormatter(value),
                      }}
                      valueFormat={(value) =>
                        `$ ${Number(value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}`
                      }
                    />
                  </div>
                  <div className="legend">
                    <div className="legend_bullet_wrap">
                      <div className="legend_bullet"></div>
                    </div>
                    <div className="legend_text">Billed Amount</div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper>
              <div id="colTrend">
                <div className="card_heading">
                  <div className="graphHeading">
                    <span>Collection Trend</span>
                    <IconButton onClick={() => handleGraphDownload("colTrend")}>
                      <GetApp color="black" />
                    </IconButton>
                  </div>
                </div>
                <div className="card_container_big">
                  {/* <div className="card_heading">
                  <div className="graphHeading">
                    <span>Collection Trend</span>
                    <IconButton>
                      <GetApp color="black" />
                    </IconButton>
                  </div>
                </div> */}
                  <div className="nivo_card_content_big">
                    <ResponsiveBar
                      data={collectionData}
                      keys={["Amount"]}
                      indexBy="month"
                      gridYValues={5}
                      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                      padding={0.5}
                      valueScale={{ type: "linear" }}
                      colors="#15AFAF"
                      enableLabel={false}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: -45,
                      }}
                      axisLeft={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "$",
                        // legendPosition: "middle",
                        legendOffset: -50,
                        format: (value) => numFormatter(value),
                      }}
                      valueFormat={(value) =>
                        `$ ${Number(value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}`
                      }
                    />
                  </div>
                  <div className="legend">
                    <div className="legend_bullet_wrap">
                      <div className="legend_bullet"></div>
                    </div>
                    <div className="legend_text">Collection</div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0}>
              <div className="subHeading">Clinic Level</div>
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper>
              <div id="notFiled">
                <div className="card_heading">
                  <div className="graphHeading">
                    <span>Not Filed Claims</span>
                    <IconButton onClick={() => handleGraphDownload("notFiled")}>
                      <GetApp color="black" />
                    </IconButton>
                  </div>
                </div>
                <div className="card_container">
                  {/* <div className="card_heading">
                  <div>Not Filed Claims</div>
                </div> */}
                  <div className="nivo_card_content">
                    <ResponsiveBar
                      data={notFiledData}
                      keys={["count"]}
                      indexBy="week"
                      gridYValues={5}
                      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                      padding={0.5}
                      valueScale={{ type: "linear" }}
                      colors="#15AFAF"
                      enableLabel={false}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: 0,
                      }}
                      axisLeft={{
                        tickSize: 1,
                        tickPadding: 1,
                        tickRotation: 0,
                        legend: "Nos",
                        legendPosition: "middle",
                        legendOffset: -60,
                      }}
                      valueFormat={(value) =>
                        ` ${Number(value).toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                        })}`
                      }
                    />
                  </div>
                  <div className="legend">
                    <div className="legend_bullet_wrap">
                      <div className="legend_bullet"></div>
                    </div>
                    <div className="legend_text">Not Filed Claims</div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <div id="recGross">
                <div className="card_heading">
                  <div className="graphHeading">
                    <span>Receivable - Gross</span>
                    <IconButton onClick={() => handleGraphDownload("recGross")}>
                      <GetApp color="black" />
                    </IconButton>
                  </div>
                </div>
                <div className="card_container">
                  {/* <div className="card_heading">
                  <div>Receivable - Gross</div>
                </div> */}
                  <div className="nivo_card_content">
                    <ResponsiveBar
                      data={grossData}
                      keys={["Amount"]}
                      indexBy="arg"
                      gridYValues={5}
                      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                      padding={0.5}
                      valueScale={{ type: "linear" }}
                      colors="#15AFAF"
                      enableLabel={false}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: 0,
                      }}
                      axisLeft={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "$",
                        legendPosition: "middle",
                        legendOffset: -40,
                        format: (value) => numFormatter(value),
                      }}
                      valueFormat={(value) =>
                        `$ ${Number(value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}`
                      }
                    />
                  </div>
                  <div className="legend">
                    <div className="legend_bullet_wrap">
                      <div className="legend_bullet"></div>
                    </div>
                    <div className="legend_text">Insurance</div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <div id="recNet">
                <div className="card_heading">
                  <div className="graphHeading">
                    <span>Receivable - Net</span>
                    <IconButton onClick={() => handleGraphDownload("recNet")}>
                      <GetApp color="black" />
                    </IconButton>
                  </div>
                </div>
                <div className="card_container">
                  {/* <div className="card_heading">
                  <div>Receivable - Net</div>
                </div> */}
                  <div className="nivo_card_content">
                    <ResponsiveBar
                      data={netData}
                      keys={["Amount"]}
                      indexBy="arg"
                      gridYValues={5}
                      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                      padding={0.5}
                      valueScale={{ type: "linear" }}
                      colors="#15AFAF"
                      enableLabel={false}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: 0,
                      }}
                      axisLeft={{
                        tickSize: 4,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "$",
                        legendPosition: "middle",
                        legendOffset: -40,
                        format: (value) => numFormatter(value),
                      }}
                      valueFormat={(value) =>
                        `$ ${Number(value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}`
                      }
                    />
                  </div>
                  <div className="legend">
                    <div className="legend_bullet_wrap">
                      <div className="legend_bullet"></div>
                    </div>
                    <div className="legend_text">A / R Net</div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  getClinicDetails: (values) => dispatch(getClinicDetails(values)),
  getProvidersList: (values) => dispatch(getProvidersList(values)),
  getFacilitiesList: (values) => dispatch(getFacilitiesList(values)),
  getProductionTrend: (values) => dispatch(getProductionTrend(values)),
  getCollectionTrend: (values) => dispatch(getCollectionTrend(values)),
  getForecast: (values) => dispatch(getForecast(values)),
  getNotFiledClaims: (values) => dispatch(getNotFiledClaims(values)),
  getReceivableNet: (values) => dispatch(getReceivableNet(values)),
  getReceivableGross: (values) => dispatch(getReceivableGross(values)),
});

Dashboard.propTypes = {
  dashboardData: PropTypes.object,
  getClinicDetails: PropTypes.func,
  getProvidersList: PropTypes.func,
  getFacilitiesList: PropTypes.func,
  getProductionTrend: PropTypes.func,
  getCollectionTrend: PropTypes.func,
  getForecast: PropTypes.func,
  getNotFiledClaims: PropTypes.func,
  getReceivableNet: PropTypes.func,
  getReceivableGross: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
