import React, { useState } from "react";
import { createContext } from "react";

export const prodDetailsContext = createContext();

const ProdContext = ({ children }) => {
  const [values, setValues] = useState({
    period: 1,
    provider: -1,
    service: -1,
    startDate: "",
    endDate: "",
    facility: "",
    payor:"",
  });

  const [selectedItem, setSelectedItem] = useState({
    patient: true,
    mrn: true,
    dos: true,
    provider: true,
    payor: true,
    facility: true,
    service: true,
    charges: true,
    payments: true,
    // balance: true,
    patBalance: true,
    insBalance: true,
    claimStatus: true,
    notes: true,
    eob:true,
  });
  const [provider, setProvider] = useState([]);
  const [services, setServices] = useState([]);
  const [service, setService] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  // const [status, setStatus] = useState([]);
  // const [startDate, setStartDate] = useState(new Date());
  var date = new Date();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [period, setPeriod] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [payor, setPayor] = useState([]);
  const [selectedPayor, setSelectedPayor] = useState([]);

  return (
    <prodDetailsContext.Provider
      value={{
        values,
        setValues,
        provider,
        setProvider,
        services,
        setServices,
        service,
        setService,
        facilities,
        setFacilities,
        selectedFacility,
        setSelectedFacility,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        period,
        setPeriod,
        selectedItem,
        setSelectedItem,
        selectedStatus,
        setSelectedStatus,
        selectedPayor,
        setSelectedPayor,
        payor,
        setPayor,
      }}
    >
      {children}
    </prodDetailsContext.Provider>
  );
};

export default ProdContext;
