import React, { useState } from "react";
import { createContext } from "react";

export const collectionDetailsContext = createContext();

const CollectionContext = ({ children }) => {
  const [values, setValues] = useState({
    period: 1,
    provider: -1,
    service: -1,
    startDate: "",
    endDate: "",
  });
  const [selectedItem, setSelectedItem] = useState({
    patient: true,
    mrn: true,
    dos: true,
    provider: true,
    facility: true,
    service: true,
    payments: true,
    claimStatus: true,
    notes: true,
    eob: true,
  });
  const [provider, setProvider] = useState([]);
  const [services, setServices] = useState([]);
  const [service, setService] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacility] = useState([]);
  // const [status, setStatus] = useState([]);
  var date = new Date();
  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [period, setPeriod] = useState(1);

  return (
    <collectionDetailsContext.Provider
      value={{
        values,
        setValues,
        provider,
        setProvider,
        services,
        setServices,
        service,
        setService,
        facilities,
        setFacilities,
        facility,
        setFacility,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        period,
        setPeriod,
        selectedItem,
        setSelectedItem,
      }}
    >
      {children}
    </collectionDetailsContext.Provider>
  );
};

export default CollectionContext;
