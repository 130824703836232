import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
// import DatePicker from "components/DatePicker";
import {
  getProvidersList,
  getServicesList,
  getFacilitiesList,
} from "Redux/Dashboard/Dashboard.actions";
import {
  getProdGroupByProvider,
  getProdGroupByFacility,
  getProdGroupByService,
} from "Redux/Production/Production.actions";
import "./style.css";
import { productionSummaryContext } from "context/ProdSummaryContext";
import { toast } from "react-hot-toast";
import BarGroup from "components/Charts/BarGroup";
import Line from "components/Charts/Line";
import CollectionTable from "./CollectionTable";
import CountTable from "./CountTable/CountTable";
import html2canvas from "html2canvas";
import LineGraph from "../Collection/graphs/LineGraph";
import { GetApp, GetAppOutlined, GetAppRounded } from "@material-ui/icons";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// const halfMonths = ["January", "February", "March", "April", "May", "June"];

// const halfMonths = () => {
//   let today = new Date();
//   let d;
//   let month = [];
//   for (let i = 6; i > 0; i -= 1) {
//     d = new Date(today.getFullYear(), today.getMonth() - i, 1);
//     month.push(months[d.getMonth()]);
//   }
//   return month;
// };

// const allMonths = () => {
//   let today = new Date();
//   let d;
//   let month = [];
//   for (let i = 12; i > 0; i -= 1) {
//     d = new Date(today.getFullYear(), today.getMonth() - i, 1);
//     month.push(months[d.getMonth()]);
//   }
//   return month;
// };

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function Production(props) {
  const clinic_id = localStorage.getItem("clinic_id");
  // const [period, setPeriod] = useState(1);
  // const [chartProviders, setChartProviders] = useState([]);
  // const [chartServices, setChartServices] = useState([]);
  // const [providers, setProviders] = useState([
  //   {
  //     name: "All providers",
  //     value: -1,
  //   },
  // ]);
  // const [providerNames, setproviderNames] = useState([]);
  // const [provider, setProvider] = useState(-1);
  // const [services, setServices] = useState([
  //   {
  //     name: "All services",
  //     value: -1,
  //   },
  // ]);
  // const [service, setService] = useState(-1);
  // const [group, setGroup] = useState(1);
  // const [rows, setRows] = useState([]);
  // const [halfRows, setHalfRows] = useState([]);
  const [billedTotals, setBilledTotals] = useState([]);
  const [halfCountTotals, setHalfCountTotals] = useState([]);
  const [countTotals, setCountTotals] = useState([]);
  const [collectionTotals, setCollectionTotals] = useState([]);
  const [balanceTotals, setBalanceTotals] = useState([]);
  // const [halfTotals, setHalfTotals] = useState([]);
  // const [chartData, setChartData] = useState([]);
  // const [halfChartData, setHalfChartData] = useState([]);
  // const [values, setValues] = useState({
  //   period: 1,
  //   group: 1,
  //   provider: -1,
  //   service: -1,
  // });
  const {
    period,
    setPeriod,
    chartProviders,
    setChartProviders,
    chartServices,
    setChartServices,
    // providers,
    // setProviders,
    provider,
    setProvider,
    services,
    setServices,
    service,
    setService,
    group,
    setGroup,
    rows,
    setRows,
    halfRows,
    setHalfRows,
    // totals,
    // setTotals,
    halfBilledTotals,
    setHalfBilledTotals,
    chartData,
    setChartData,
    halfChartData,
    setHalfChartData,
    values,
    setValues,
    display,
    setDisplay,
    selectedFacility,
    setSelectedFacility,
    // facilities,
    // setFacilities,
  } = useContext(productionSummaryContext);
  const [fullMonths, setFullMonths] = useState([]);
  const [halfCollectionTotals, setHalfCollectionTotals] = useState([]);
  const [halfBalanceTotals, setHalfBalanceTotals] = useState([]);
  const [halfLineData, setHalfLineData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [firstRow, setFirstRow] = useState([]);
  const [secondRow, setSecondRow] = useState([]);
  const [providers, setProviders] = useState([
    {
      name: "All providers",
      value: -1,
    },
  ]);
  const [filter, setFilter] = useState(2);

  const periods = [
    { name: "Last 6 months", value: 1 },
    { name: "Last 12 months", value: 2 },
  ];

  const groups = [
    { name: "Group by Provider", value: 1 },
    { name: "Group by Facility Type", value: 2 },
    { name: "Group by Service", value: 3 },
    // { name: "Group at Clinic Level", value: 4 },
  ];

  const displayType = [
    { name: "Amount ($)", value: 1 },
    { name: "Count (#)", value: 2 },
  ];

  const facilityTypes = [
    { name: "All Facility Types", value: -1 },
    { name: "OFFICE", value: 1 },
    { name: "HOSPITAL", value: 3 },
  ];

  const formatNumber = (number) => {
    return Math.ceil(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleGroupChange = (event) => {
    let value = event.target.value;
    if (value === 1 && filter === 1) {
      setFilter(2);
    }
    if (value === 2 && filter === 2) {
      setFilter(1);
    }
    if (value === 3 && filter === 3) {
      setFilter(1);
    }
    setGroup(value);
  };

  const handleDisplayChange = (event) => {
    setDisplay(event.target.value);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleFacilityChange = (event) => {
    setSelectedFacility(event.target.value);
  };

  const handleGroupByProvider = (clinicId, group, filter, filterValue) => {
    setValues({
      period: period,
      group: group,
      provider: provider,
      display: displayType,
      service: service,
      facility: selectedFacility,
    });

    props.getProdGroupByProvider({
      clinicId: clinic_id,
      group: group,
      filter: filter,
      filterValue: filterValue,
    });
  };

  const handleGroupByFacility = (clinicId, group, filter, filterValue) => {
    setValues({
      period: period,
      group: group,
      display: displayType,
      provider: provider,
      service: service,
      facility: selectedFacility,
    });

    props.getProdGroupByFacility({
      clinicId: clinic_id,
      group: group,
      filter: filter,
      filterValue: filterValue,
    });
  };

  const handleGroupByService = (clinicId, group, filter, filterValue) => {
    setValues({
      period: period,
      group: group,
      display: displayType,
      provider: provider,
      service: service,
      facility: selectedFacility,
    });
    props.getProdGroupByService({
      clinicId: clinicId,
      group: group,
      filter: filter,
      filterValue: filterValue,
    });
  };

  // const clearFilters = () => {
  //   setSelectedFacility("");
  //   setService("");
  //   setProvider("");
  // };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleGraphDownload = async () => {
    if (display === 1) {
      const element = document.getElementById("barGraph"),
        canvas = await html2canvas(element),
        data = canvas.toDataURL("image/png"),
        link = document.createElement("a");
      link.href = data;
      link.download = "downloaded-image.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (display === 2) {
      console.log("display 111");
      const element = document.getElementById("lineGraph"),
        canvas = await html2canvas(element),
        data = canvas.toDataURL("image/png"),
        link = document.createElement("a");
      link.href = data;
      link.download = "downloaded-image.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFilter = () => {
    // setValues({
    //   period: period,
    //   group: group,
    //   provider: provider,
    //   service: service,
    // });
    if (group === 1) {
      //facility is selected
      if (filter === 2) {
        if (selectedFacility !== "") {
          handleGroupByProvider(clinic_id, 1, 2, selectedFacility);
        } else {
          toast.error("Select atleast one filter");
        }
      }
      //service is selected
      if (filter === 3) {
        if (service !== "") {
          handleGroupByProvider(clinic_id, 1, 3, service);
        } else {
          toast.error("Select atleast one filter");
        }
      }
    }
    //group by facility
    if (group === 2) {
      if (filter === 1) {
        if (provider !== "") {
          handleGroupByFacility(clinic_id, 2, 1, provider);
        } else {
          toast.error("Select atleast one filter");
        }
      }
      //service is selected
      if (filter === 3) {
        if (service !== "") {
          handleGroupByFacility(clinic_id, 2, 3, service);
        } else {
          toast.error("Select atleast one filter");
        }
      }
    }
    //gorup by service
    if (group === 3) {
      //faclitity is selected
      if (filter === 2) {
        if (selectedFacility !== "") {
          handleGroupByService(clinic_id, 3, 2, selectedFacility);
        } else {
          toast.error("Select atleast one filter");
        }
      }
      //provider is selected
      if (filter === 1) {
        if (provider !== "") {
          handleGroupByService(clinic_id, 3, 1, provider);
        } else {
          toast.error("Select atleast one filter");
        }
      }
      // if (selectedFacility === "" && provider === "") {
      //   toast.error("Select atleast one filter");
      // }
    }
  };

  useEffect(() => {
    props.getProvidersList(clinic_id);
    props.getServicesList({
      start: 0,
      limit: 100,
    });
    // props.getFacilitiesList(clinic_id);
    // if (group === 1) {
    //   props.getProdGroupByProvider({
    //     clinicId: clinic_id,
    //     serviceId: -1,
    //   });
    // }
    if (group === 1) {
      props.getProdGroupByProvider({
        clinicId: clinic_id,
        group: 1,
        filter: 2,
        filterValue: -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.providersList &&
      props.dashboardData.providersList.response
    ) {
      let data = props.dashboardData.providersList.response.data;
      let providersArray = data
        .filter((item) => item.active === 1)
        .map((item) => {
          return {
            name: item.firstName + " " + item.middleName + " " + item.lastName,
            value: item.id,
          };
        });
      // providersArray.unshift({
      //   name: "All providers",
      //   value: -1,
      // });
      let newArray = [
        {
          name: "All providers",
          value: -1,
        },
        ...providersArray,
      ];
      setProviders(newArray);
      // let demoProviders = [
      //   { name: "All providers", value: -1 },
      //   { name: "Sanjeev Nair", value: 208 },
      //   { name: "Nikhil Nalluri", value: 209 },
      //   { name: "John Hibbits", value: 210 },
      //   { name: "Brian Schwartz", value: 211 },
      //   { name: "Venkata Aligeti", value: 212 },
      //   { name: "Vinit Lal", value: 213 },
      //   { name: "Kelsie Reekie", value: 214 },
      //   { name: "Randall Gale", value: 215 },
      //   { name: "Deepak Asti", value: 216 },
      // ];
      // setProviders(demoProviders);
      setChartProviders(
        data.map((item) => item.firstName + " " + item.lastName)
      );
      // setChartProviders(demoProviders.map((item) => item.name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.providersList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.servicesList &&
      props.dashboardData.servicesList.response
    ) {
      if (props.dashboardData.servicesList.response.responseCode === 0) {
        let data = props.dashboardData.servicesList.response.data.results;
        let servicesArray = data.map((item) => {
          return {
            name: item.name,
            value: item.id,
          };
        });
        let newArray = [
          {
            name: "All services",
            value: -1,
          },
          ...servicesArray,
        ];
        setServices(newArray);
        setChartServices(servicesArray.map((item) => item.name));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.servicesList.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.ProdGroupByProvider &&
      props.prodData.ProdGroupByProvider.response
    ) {
      if (props.prodData.ProdGroupByProvider.response.responseCode === 0) {
        let data = props.prodData.ProdGroupByProvider.response.data;
        // console.log(data);
        const getSum = (rowArray) => {
          let sum = rowArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          return sum;
        };
        setRows(
          data
            .map((item) => {
              return {
                providerId: item.providerId,
                // providerName: item.providerName,
                providerName:
                  item.providerLastName +
                  ", " +
                  item.providerFirstName.substring(0, 1),
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices,
                rowTotal: getSum(item.productivityTrendProviderServices),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setFullMonths(
          data[0].productivityTrendProviderServices.map((item) => item.month)
        );
        setFirstRow(
          data
            .map((item) => {
              return {
                providerId: item.providerId,
                // providerName: item.providerName,
                providerName:
                  item.providerLastName +
                  ", " +
                  item.providerFirstName.substring(0, 1),
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices.slice(0, 6),
                rowTotal: getSum(
                  item.productivityTrendProviderServices.slice(0, 6)
                ),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );

        setHalfRows(
          data
            .map((item) => {
              return {
                providerId: item.providerId,
                // providerName: item.providerName,
                providerName:
                  item.providerLastName +
                  ", " +
                  item.providerFirstName.substring(0, 1),
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices.slice(6, 12),
                rowTotal: getSum(
                  item.productivityTrendProviderServices.slice(6, 12)
                ),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        let flatArray = data
          .map((item) => item.productivityTrendProviderServices)
          .flat();
        console.log(flatArray);
        let totalJan = 0;
        let totalFeb = 0;
        let totalMar = 0;
        let totalApr = 0;
        let totalMay = 0;
        let totalJun = 0;
        let totalJul = 0;
        let totalAug = 0;
        let totalSep = 0;
        let totalOct = 0;
        let totalNov = 0;
        let totalDec = 0;
        const getTotal = (month) => {
          let newArray = flatArray
            .filter((item) => (item.month === month ? item : null))
            .map((item) =>
              item
                ? {
                    collection: item.collection,
                    billed: item.billed,
                    balance: item.balance,
                    year: item.year,
                    month: item.month,
                    count: item.count,
                  }
                : null
            );
          let totalcollection = newArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          let totalBilled = newArray
            .map((item) => item.billed)
            .reduce((total, current) => total + current, 0);
          let totalBalance = newArray
            .map((item) => item.balance)
            .reduce((total, current) => total + current, 0);
          let totalCount = newArray
            .map((item) => item.count)
            .reduce((total, current) => total + current, 0);
          if (newArray.length) {
            return {
              collection: totalcollection,
              billed: totalBilled,
              balance: totalBalance,
              count: totalCount,
              month: month,
              year: newArray[0].year,
            };
          } else {
            return null;
          }
        };
        totalJan = getTotal(1);
        totalFeb = getTotal(2);
        totalMar = getTotal(3);
        totalApr = getTotal(4);
        totalMay = getTotal(5);
        totalJun = getTotal(6);
        totalJul = getTotal(7);
        totalAug = getTotal(8);
        totalSep = getTotal(9);
        totalOct = getTotal(10);
        totalNov = getTotal(11);
        totalDec = getTotal(12);
        let totalsArray = [
          totalJan ? totalJan : null,
          totalFeb ? totalFeb : null,
          totalMar ? totalMar : null,
          totalApr ? totalApr : null,
          totalMay ? totalMay : null,
          totalJun ? totalJun : null,
          totalJul ? totalJul : null,
          totalAug ? totalAug : null,
          totalSep ? totalSep : null,
          totalOct ? totalOct : null,
          totalNov ? totalNov : null,
          totalDec ? totalDec : null,
        ];
        setBilledTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.billed))
            .slice(0, 6)
        );
        setCollectionTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.collection))
            .slice(0, 6)
        );
        setBalanceTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.balance))
            .slice(0, 6)
        );
        setCountTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.count))
        );

        setHalfBilledTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.billed))
            .slice(6, 12)
        );
        setHalfCollectionTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.collection))
            .slice(6, 12)
        );
        setHalfBalanceTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.balance))
            .slice(6, 12)
        );
        setHalfCountTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.count))
            .slice(6, 12)
        );

        let jan = { month: "Jan" };
        let feb = { month: "Feb" };
        let mar = { month: "Mar" };
        let apr = { month: "Apr" };
        let may = { month: "May" };
        let jun = { month: "Jun" };
        let jul = { month: "Jul" };
        let aug = { month: "Aug" };
        let sep = { month: "Sep" };
        let oct = { month: "Oct" };
        let nov = { month: "Nov" };
        let dec = { month: "Dec" };
        data.forEach((item) => {
          item.productivityTrendProviderServices.forEach((x, i) => {
            if (x.month === 1) {
              jan[item.providerName] = x.collection;
              jan[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jan["id"] = i;
            }
            if (x.month === 2) {
              feb[item.providerName] = x.collection;
              feb[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              feb["id"] = i;
            }
            if (x.month === 3) {
              mar[item.providerName] = x.collection;
              mar[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              mar["id"] = i;
            }
            if (x.month === 4) {
              apr[item.providerName] = x.collection;
              apr[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              apr["id"] = i;
            }
            if (x.month === 5) {
              may[item.providerName] = x.collection;
              may[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              may["id"] = i;
            }
            if (x.month === 6) {
              jun[item.providerName] = x.collection;
              jun[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jun["id"] = i;
            }
            if (x.month === 7) {
              jul[item.providerName] = x.collection;
              jul[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jul["id"] = i;
            }
            if (x.month === 8) {
              aug[item.providerName] = x.collection;
              aug[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              aug["id"] = i;
            }
            if (x.month === 9) {
              sep[item.providerName] = x.collection;
              sep[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              sep["id"] = i;
            }
            if (x.month === 10) {
              oct[item.providerName] = x.collection;
              oct[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              oct["id"] = i;
            }
            if (x.month === 11) {
              nov[item.providerName] = x.collection;
              nov[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              nov["id"] = i;
            }
            if (x.month === 12) {
              dec[item.providerName] = x.collection;
              dec[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              dec["id"] = i;
            }
          });
        });

        let graphData = [
          jan,
          feb,
          mar,
          apr,
          may,
          jun,
          jul,
          aug,
          sep,
          oct,
          nov,
          dec,
        ].sort((a, b) => a.id - b.id);

        let chartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.providerName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.productivityTrendProviderServices.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.count };
            }),
          };
          return chartObj;
        });
        setLineData(chartArray);
        setHalfLineData(
          chartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );
        //
        let BarChartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.providerName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.productivityTrendProviderServices.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.collection };
            }),
          };
          return chartObj;
        });
        setChartData(BarChartArray);
        setHalfChartData(
          BarChartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.ProdGroupByProvider.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.ProdGroupByService &&
      props.prodData.ProdGroupByService.response
    ) {
      if (props.prodData.ProdGroupByService.response.responseCode === 0) {
        let data = props.prodData.ProdGroupByService.response.data;
        const getSum = (rowArray) => {
          let sum = rowArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          return sum;
        };
        setRows(
          data
            .map((item) => {
              return {
                serviceId: item.serviceId,
                serviceName: item.serviceName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices,
                rowTotal: getSum(item.productivityTrendProviderServices),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setFullMonths(
          data[0].productivityTrendProviderServices.map((item) => item.month)
        );
        setFirstRow(
          data
            .map((item) => {
              return {
                serviceId: item.serviceId,
                serviceName: item.serviceName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices.slice(0, 6),
                rowTotal: getSum(
                  item.productivityTrendProviderServices.slice(0, 6)
                ),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );

        setHalfRows(
          data
            .map((item) => {
              return {
                serviceId: item.serviceId,
                serviceName: item.serviceName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices.slice(6, 12),
                rowTotal: getSum(
                  item.productivityTrendProviderServices.slice(6, 12)
                ),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        let flatArray = data
          .map((item) => item.productivityTrendProviderServices)
          .flat();
        let totalJan = 0;
        let totalFeb = 0;
        let totalMar = 0;
        let totalApr = 0;
        let totalMay = 0;
        let totalJun = 0;
        let totalJul = 0;
        let totalAug = 0;
        let totalSep = 0;
        let totalOct = 0;
        let totalNov = 0;
        let totalDec = 0;
        const getTotal = (month) => {
          let newArray = flatArray
            .filter((item) => (item.month === month ? item : null))
            .map((item) =>
              item
                ? {
                    collection: item.collection,
                    year: item.year,
                    month: item.month,
                    billed: item.billed,
                    balance: item.balance,
                    count: item.count,
                  }
                : null
            );
          let totalcollection = newArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          let totalBilled = newArray
            .map((item) => item.billed)
            .reduce((total, current) => total + current, 0);
          let totalBalance = newArray
            .map((item) => item.balance)
            .reduce((total, current) => total + current, 0);
          let totalCount = newArray
            .map((item) => item.count)
            .reduce((total, current) => total + current, 0);
          if (newArray.length) {
            return {
              collection: totalcollection,
              billed: totalBilled,
              balance: totalBalance,
              count: totalCount,
              month: month,
              year: newArray[0].year,
            };
          } else {
            return null;
          }
        };
        totalJan = getTotal(1);
        totalFeb = getTotal(2);
        totalMar = getTotal(3);
        totalApr = getTotal(4);
        totalMay = getTotal(5);
        totalJun = getTotal(6);
        totalJul = getTotal(7);
        totalAug = getTotal(8);
        totalSep = getTotal(9);
        totalOct = getTotal(10);
        totalNov = getTotal(11);
        totalDec = getTotal(12);
        let totalsArray = [
          totalJan ? totalJan : null,
          totalFeb ? totalFeb : null,
          totalMar ? totalMar : null,
          totalApr ? totalApr : null,
          totalMay ? totalMay : null,
          totalJun ? totalJun : null,
          totalJul ? totalJul : null,
          totalAug ? totalAug : null,
          totalSep ? totalSep : null,
          totalOct ? totalOct : null,
          totalNov ? totalNov : null,
          totalDec ? totalDec : null,
        ];
        setBilledTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.billed))
            .slice(0, 6)
        );
        setCollectionTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.collection))
            .slice(0, 6)
        );
        setBalanceTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.balance))
            .slice(0, 6)
        );
        setCountTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.count))
        );
        setHalfBilledTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.billed))
            .slice(6, 12)
        );
        setHalfCollectionTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.collection))
            .slice(6, 12)
        );
        setHalfBalanceTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.balance))
            .slice(6, 12)
        );
        setHalfCountTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.count))
            .slice(6, 12)
        );

        let jan = { month: "Jan" };
        let feb = { month: "Feb" };
        let mar = { month: "Mar" };
        let apr = { month: "Apr" };
        let may = { month: "May" };
        let jun = { month: "Jun" };
        let jul = { month: "Jul" };
        let aug = { month: "Aug" };
        let sep = { month: "Sep" };
        let oct = { month: "Oct" };
        let nov = { month: "Nov" };
        let dec = { month: "Dec" };
        data.forEach((item) => {
          item.productivityTrendProviderServices.forEach((x, i) => {
            if (x.month === 1) {
              jan[item.serviceName] = x.collection;
              jan[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jan["id"] = i;
            }
            if (x.month === 2) {
              feb[item.serviceName] = x.collection;
              feb[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              feb["id"] = i;
            }
            if (x.month === 3) {
              mar[item.serviceName] = x.collection;
              mar[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              mar["id"] = i;
            }
            if (x.month === 4) {
              apr[item.serviceName] = x.collection;
              apr[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              apr["id"] = i;
            }
            if (x.month === 5) {
              may[item.serviceName] = x.collection;
              may[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              may["id"] = i;
            }
            if (x.month === 6) {
              jun[item.serviceName] = x.collection;
              jun[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jun["id"] = i;
            }
            if (x.month === 7) {
              jul[item.serviceName] = x.collection;
              jul[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jul["id"] = i;
            }
            if (x.month === 8) {
              aug[item.serviceName] = x.collection;
              aug[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              aug["id"] = i;
            }
            if (x.month === 9) {
              sep[item.serviceName] = x.collection;
              sep[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              sep["id"] = i;
            }
            if (x.month === 10) {
              oct[item.serviceName] = x.collection;
              oct[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              oct["id"] = i;
            }
            if (x.month === 11) {
              nov[item.serviceName] = x.collection;
              nov[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              nov["id"] = i;
            }
            if (x.month === 12) {
              dec[item.serviceName] = x.collection;
              dec[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              dec["id"] = i;
            }
          });
        });

        let graphData = [
          jan,
          feb,
          mar,
          apr,
          may,
          jun,
          jul,
          aug,
          sep,
          oct,
          nov,
          dec,
        ].sort((a, b) => a.id - b.id);
        // setChartData(graphData);
        // setHalfChartData(graphData.slice(6, 12));

        let chartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.serviceName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.productivityTrendProviderServices.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.count };
            }),
          };
          return chartObj;
        });
        setLineData(chartArray);
        setHalfLineData(
          chartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );

        let BarChartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.serviceName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.productivityTrendProviderServices.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.collection };
            }),
          };
          return chartObj;
        });
        setChartData(BarChartArray);
        setHalfChartData(
          BarChartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.ProdGroupByService.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.ProdGroupByFacility &&
      props.prodData.ProdGroupByFacility.response
    ) {
      if (props.prodData.ProdGroupByFacility.response.responseCode === 0) {
        let data = props.prodData.ProdGroupByFacility.response.data;
        const getSum = (rowArray) => {
          let sum = rowArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          return sum;
        };
        setRows(
          data
            .map((item) => {
              return {
                facilityId: item.facilityId,
                facilityName: item.facilityName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices,
                rowTotal: getSum(item.productivityTrendProviderServices),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setFullMonths(
          data[0].productivityTrendProviderServices.map((item) => item.month)
        );
        setFirstRow(
          data
            .map((item) => {
              return {
                facilityId: item.facilityId,
                facilityName: item.facilityName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices.slice(0, 6),
                rowTotal: getSum(
                  item.productivityTrendProviderServices.slice(0, 6)
                ),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setHalfRows(
          data
            .map((item) => {
              return {
                facilityId: item.facilityId,
                facilityName: item.facilityName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices.slice(6, 12),
                rowTotal: getSum(
                  item.productivityTrendProviderServices.slice(6, 12)
                ),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        let flatArray = data
          .map((item) => item.productivityTrendProviderServices)
          .flat();
        let totalJan = 0;
        let totalFeb = 0;
        let totalMar = 0;
        let totalApr = 0;
        let totalMay = 0;
        let totalJun = 0;
        let totalJul = 0;
        let totalAug = 0;
        let totalSep = 0;
        let totalOct = 0;
        let totalNov = 0;
        let totalDec = 0;
        const getTotal = (month) => {
          let newArray = flatArray
            .filter((item) => (item.month === month ? item : null))
            .map((item) =>
              item
                ? {
                    collection: item.collection,
                    year: item.year,
                    month: item.month,
                    billed: item.billed,
                    count: item.count,
                    balance: item.balance,
                  }
                : null
            );
          let totalcollection = newArray
            .map((item) => item.collection)
            .reduce((total, current) => total + current, 0);
          let totalBilled = newArray
            .map((item) => item.billed)
            .reduce((total, current) => total + current, 0);
          let totalBalance = newArray
            .map((item) => item.balance)
            .reduce((total, current) => total + current, 0);
          let totalCount = newArray
            .map((item) => item.count)
            .reduce((total, current) => total + current, 0);
          if (newArray.length) {
            return {
              collection: totalcollection,
              month: month,
              billed: totalBilled,
              balance: totalBalance,
              count: totalCount,
              year: newArray[0].year,
            };
          } else {
            return null;
          }
        };
        totalJan = getTotal(1);
        totalFeb = getTotal(2);
        totalMar = getTotal(3);
        totalApr = getTotal(4);
        totalMay = getTotal(5);
        totalJun = getTotal(6);
        totalJul = getTotal(7);
        totalAug = getTotal(8);
        totalSep = getTotal(9);
        totalOct = getTotal(10);
        totalNov = getTotal(11);
        totalDec = getTotal(12);
        let totalsArray = [
          totalJan ? totalJan : null,
          totalFeb ? totalFeb : null,
          totalMar ? totalMar : null,
          totalApr ? totalApr : null,
          totalMay ? totalMay : null,
          totalJun ? totalJun : null,
          totalJul ? totalJul : null,
          totalAug ? totalAug : null,
          totalSep ? totalSep : null,
          totalOct ? totalOct : null,
          totalNov ? totalNov : null,
          totalDec ? totalDec : null,
        ];
        setBilledTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.billed))
            .slice(0, 6)
        );
        setCollectionTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.collection))
            .slice(0, 6)
        );
        setBalanceTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.balance))
            .slice(0, 6)
        );

        setCountTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.count))
        );

        setHalfBilledTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.billed))
            .slice(6, 12)
        );
        setHalfCollectionTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.collection))
            .slice(6, 12)
        );
        setHalfBalanceTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.balance))
            .slice(6, 12)
        );
        setHalfCountTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => formatNumber(item.count))
            .slice(6, 12)
        );
        let jan = { month: "Jan" };
        let feb = { month: "Feb" };
        let mar = { month: "Mar" };
        let apr = { month: "Apr" };
        let may = { month: "May" };
        let jun = { month: "Jun" };
        let jul = { month: "Jul" };
        let aug = { month: "Aug" };
        let sep = { month: "Sep" };
        let oct = { month: "Oct" };
        let nov = { month: "Nov" };
        let dec = { month: "Dec" };
        // data.forEach((item) => {
        //   item.productivityTrendProviderServices.forEach((x, i) => {
        //     if (x.month === 1) {
        //       jan[item.facilityName] = x.collection;
        //       jan[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       jan["id"] = i;
        //     }
        //     if (x.month === 2) {
        //       feb[item.facilityName] = x.collection;
        //       feb[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       feb["id"] = i;
        //     }
        //     if (x.month === 3) {
        //       mar[item.facilityName] = x.collection;
        //       mar[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       mar["id"] = i;
        //     }
        //     if (x.month === 4) {
        //       apr[item.facilityName] = x.collection;
        //       apr[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       apr["id"] = i;
        //     }
        //     if (x.month === 5) {
        //       may[item.facilityName] = x.collection;
        //       may[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       may["id"] = i;
        //     }
        //     if (x.month === 6) {
        //       jun[item.facilityName] = x.collection;
        //       jun[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       jun["id"] = i;
        //     }
        //     if (x.month === 7) {
        //       jul[item.facilityName] = x.collection;
        //       jul[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
        //       jul["id"] = i;
        //     }
        //     if (x.month === 8) {
        //       aug[item.facilityName] = x.collection;
        //       aug[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       aug["id"] = i;
        //     }
        //     if (x.month === 9) {
        //       sep[item.facilityName] = x.collection;
        //       sep[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       sep["id"] = i;
        //     }
        //     if (x.month === 10) {
        //       oct[item.facilityName] = x.collection;
        //       oct[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
        //       oct["id"] = i;
        //     }
        //     if (x.month === 11) {
        //       nov[item.facilityName] = x.collection;
        //       nov[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       nov["id"] = i;
        //     }
        //     if (x.month === 12) {
        //       dec[item.facilityName] = x.collection;
        //       dec[item.facilityName + "Color"] = "hsl(1, 70%, 50%)";
        //       dec["id"] = i;
        //     }
        //   });
        // });

        // let graphData = [
        //   jan,
        //   feb,
        //   mar,
        //   apr,
        //   may,
        //   jun,
        //   jul,
        //   aug,
        //   sep,
        //   oct,
        //   nov,
        //   dec,
        // ].sort((a, b) => a.id - b.id);
        // setChartData(graphData);
        // setHalfChartData(graphData.slice(6, 12));

        let chartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.facilityName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.productivityTrendProviderServices.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.count };
            }),
          };
          return chartObj;
        });
        setLineData(chartArray);
        setHalfLineData(
          chartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );

        let BarChartArray = data.map((item) => {
          let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
          let chartObj = {
            id: item.facilityName,
            color: `hsl(${random}, 70%, 50%)`,
            data: item.productivityTrendProviderServices.map((el) => {
              return { x: shortMonths[el.month - 1], y: el.collection };
            }),
          };
          return chartObj;
        });
        setChartData(BarChartArray);
        setHalfChartData(
          BarChartArray.map((item) => {
            return {
              id: item.id,
              color: item.color,
              data: item.data.slice(6, 12),
            };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.ProdGroupByFacility.response]);

  return (
    <div className="prodSummary_root">
      {/* <div className="heading">Production Summary</div> */}
      <div>
        <div className="searchbar_container_prod">
          <div className="dropdown_wrap_container">
            <FormControl className="filter_dropdown_wrap">
              <InputLabel>Display</InputLabel>
              <Select
                className="dropdown_prod"
                value={display}
                onChange={handleDisplayChange}
              >
                {displayType &&
                  displayType.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="dropdown_wrap_container">
            <FormControl className="filter_dropdown_wrap">
              <InputLabel>Group by</InputLabel>
              <Select
                className="dropdown_prod"
                value={group}
                onChange={(e) => handleGroupChange(e)}
              >
                {groups &&
                  groups.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="dropdown_wrap_container">
            <FormControl className="filter_dropdown_wrap">
              <InputLabel>Filter By</InputLabel>
              <Select
                className="dropdown_prod"
                value={filter}
                onChange={(e) => handleFilterChange(e)}
              >
                <MenuItem disabled={group === 1} value={1}>
                  Provider
                </MenuItem>
                <MenuItem disabled={group === 2} value={2}>
                  Facility Type
                </MenuItem>
                <MenuItem disabled={group === 3} value={3}>
                  Service
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {filter === 2 ? (
            <div className="dropdown_wrap_container">
              <FormControl className="filter_dropdown_wrap">
                <InputLabel>Facility Types</InputLabel>
                <Select
                  className="dropdown_prod"
                  value={selectedFacility}
                  // disabled={service !== ""}
                  onChange={(e) => handleFacilityChange(e)}
                >
                  {facilityTypes &&
                    facilityTypes.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  {/* {facilities &&
                    facilities.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))} */}
                </Select>
              </FormControl>
            </div>
          ) : null}
          {filter === 3 ? (
            <div className="dropdown_wrap_container">
              <FormControl className="filter_dropdown_wrap">
                <InputLabel>Service</InputLabel>
                <Select
                  className="dropdown_prod"
                  value={service}
                  // disabled={selectedFacility !== ""}
                  onChange={(e) => handleServiceChange(e)}
                >
                  {services &&
                    services.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          ) : null}
          {filter === 1 ? (
            <div className="dropdown_wrap_container">
              <FormControl className="filter_dropdown_wrap">
                <InputLabel>Provider</InputLabel>
                <Select
                  className="dropdown_prod"
                  value={provider}
                  // disabled={service !== ""}
                  onChange={(e) => handleProviderChange(e)}
                >
                  {providers &&
                    providers.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          ) : null}
          <div className="dropdown_wrap_container">
            <FormControl className="filter_dropdown_wrap">
              <InputLabel>Period</InputLabel>
              <Select value={period} onChange={(e) => handlePeriodChange(e)}>
                {periods &&
                  periods.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="Btn_wrap">
            <div className="filterBtn_wrap">
              <Button
                className="filterBtn"
                variant="contained"
                color="primary"
                onClick={() => handleFilter()}
              >
                Filter
              </Button>
            </div>
            {/* <div className="filterBtn_wrap">
              <Button
                className="downloadBtn"
                variant="outlined"
                color="primary"
                onClick={() => handleGraphDownload()}
              >
                Print
              </Button>
            </div> */}
          </div>
        </div>
        {display === 1 ? (
          <div className="graph_container">
            <div className="graph_card">
              <div className="graph_heading">
                {filter === 1
                  ? values.provider === "" || values.provider === -1
                    ? "Total Production"
                    : providers.filter(
                        (item) => item.value === values.provider
                      )[0].name
                  : filter === 2
                  ? values.facility === "" || values.facility === -1
                    ? "Total Production"
                    : facilityTypes.filter(
                        (item) => item.value === values.facility
                      )[0].name
                  : filter === 3
                  ? values.service === "" || values.service === -1
                    ? "Total Production"
                    : services.filter(
                        (item) => item.value === values.service
                      )[0].name
                  : ""}
                {/* <IconButton onClick={handleGraphDownload}>
                  <GetApp />
                </IconButton> */}
                <Button
                  onClick={handleGraphDownload}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  <GetApp />
                </Button>
              </div>
              <div id="barGraph" className="graph_content">
                {/* <BarGroup
                  period={values.period}
                  group={values.group}
                  providerNames={chartProviders}
                  facilityNames={chartFacilities}
                  serviceNames={chartServices}
                  chartData={chartData}
                  halfChartData={halfChartData}
                /> */}
                <LineGraph
                  period={values.period}
                  // group={group}
                  chartData={chartData}
                  halfChartData={halfChartData}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="graph_container">
            <div className="graph_card">
              <div className="graph_heading">
                {filter === 1
                  ? values.provider === "" || values.provider === -1
                    ? "Count"
                    : providers.filter(
                        (item) => item.value === values.provider
                      )[0].name
                  : filter === 2
                  ? values.facility === "" || values.facility === -1
                    ? "Count"
                    : facilityTypes.filter(
                        (item) => item.value === values.facility
                      )[0].name
                  : filter === 3
                  ? values.service === "" || values.service === -1
                    ? "Count"
                    : services.filter(
                        (item) => item.value === values.service
                      )[0].name
                  : ""}
                <Button
                  onClick={handleGraphDownload}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  <GetApp />
                </Button>
              </div>
              <div id="lineGraph" className="graph_content">
                <Line
                  period={values.period}
                  // group={group}
                  chartData={lineData}
                  halfChartData={halfLineData}
                />
              </div>
            </div>
          </div>
        )}

        {display === 1 ? (
          <>
            <div className="tables">
              <CollectionTable
                values={values}
                rows={halfRows}
                billedTotals={halfBilledTotals}
                fullMonths={fullMonths.slice(6, 12)}
                collectionTotals={halfCollectionTotals}
                balanceTotals={halfBalanceTotals}
                formatNumber={formatNumber}
              />
            </div>

            {values.period === 2 && (
              <CollectionTable
                values={values}
                rows={firstRow}
                fullMonths={fullMonths.slice(0, 6)}
                billedTotals={billedTotals}
                collectionTotals={collectionTotals}
                balanceTotals={balanceTotals}
                formatNumber={formatNumber}
              />
            )}
          </>
        ) : (
          <CountTable
            values={values}
            filter={filter}
            fullMonths={fullMonths}
            provier={providers}
            facilityTypes={facilityTypes}
            services={services}
            halfRows={halfRows}
            rows={rows}
            countTotals={countTotals}
            halfCountTotals={halfCountTotals}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
  prodData: state.production,
});

const mapDispatchToProps = (dispatch) => ({
  getProvidersList: (values) => dispatch(getProvidersList(values)),
  getServicesList: (values) => dispatch(getServicesList(values)),
  getFacilitiesList: (values) => dispatch(getFacilitiesList(values)),
  getProdGroupByProvider: (values) => dispatch(getProdGroupByProvider(values)),
  getProdGroupByFacility: (values) => dispatch(getProdGroupByFacility(values)),
  getProdGroupByService: (values) => dispatch(getProdGroupByService(values)),
});

Production.propTypes = {
  dashboardData: PropTypes.object,
  prodData: PropTypes.object,
  getProvidersList: PropTypes.func,
  getServicesList: PropTypes.func,
  getProdGroupByProvider: PropTypes.func,
  getProdGroupByFacility: PropTypes.func,
  getProdGroupByService: PropTypes.func,
  getFacilitiesList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Production);
