import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

function CountTable({
  values,
  halfRows,
  rows,
  halfCountTotals,
  countTotals,
  filter,
  providers,
  facilityTypes,
  services,
  fullMonths,
}) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    console.log("count:", halfCountTotals);
  }, []);

  return (
    <React.Fragment>
      <div className="table_container">
        <div className="appsTab__container">
          <TableContainer component={Paper} elevation={0}>
            {/* group By Provider */}
            {values.group === 1 ? (
              <Table aria-label="sticky table">
                <TableHead>
                  {values.period === 1 ? (
                    <TableRow>
                      <TableCell className="prod_table_head_cell provider_name">
                        <TableSortLabel>
                          {/* {values.service === -1
                              ? "Total"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name} */}
                          {/* {values.facility !== ""
                            ? values.facility === -1
                              ? ""
                              : facilityTypes.filter(
                                  (item) => item.value === values.facility
                                )[0].name
                            : values.service !== ""
                            ? values.service === -1
                              ? "Total"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name
                            : ""} */}
                          {filter === 1
                            ? values.provider === "" || values.provider === -1
                              ? "Count"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name
                            : filter === 2
                            ? values.facility === "" || values.facility === -1
                              ? "Count"
                              : facilityTypes.filter(
                                  (item) => item.value === values.facility
                                )[0].name
                            : filter === 3
                            ? values.service === "" || values.service === -1
                              ? "Count"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name
                            : "null"}
                        </TableSortLabel>
                      </TableCell>
                      {/* {halfMonths.map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                      {halfRows.length > 0
                        ? halfRows[0].productivityTrendProviderServices.map(
                            (item, i) => (
                              <TableCell
                                key={i}
                                className="prod_table_head_cell"
                                align="center"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            )
                          )
                        : fullMonths?.slice(6, 12).map((item) => (
                            <TableCell
                              className="prod_table_head_cell month_name"
                              align="center"
                            >
                              {months[item - 1]}
                            </TableCell>
                          ))}
                    </TableRow>
                  ) : values.period === 2 ? (
                    <TableRow>
                      <TableCell className="prod_table_head_cell provider_name">
                        {/*  */}
                      </TableCell>
                      {rows.length > 0
                        ? rows[0].productivityTrendProviderServices.map(
                            (item, i) => (
                              <TableCell
                                key={i}
                                className="prod_table_head_cell"
                                align="center"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            )
                          )
                        : fullMonths?.map((item) => (
                            <TableCell
                              className="prod_table_head_cell month_name"
                              align="center"
                            >
                              {months[item - 1]}
                            </TableCell>
                          ))}
                    </TableRow>
                  ) : (
                    ""
                  )}
                </TableHead>

                {values.period === 1 ? (
                  <TableBody>
                    {halfRows
                      ? halfRows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="provider_name">
                              {item.providerName}
                            </TableCell>
                            {item.productivityTrendProviderServices &&
                              item.productivityTrendProviderServices.map(
                                (element) => (
                                  <TableCell
                                    className="collection_sub_cell"
                                    key={element.collectionId}
                                    align="center"
                                  >
                                    {element.count
                                      ? parseFloat(
                                          element.count,
                                          10
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 0,
                                        })
                                      : "0"}
                                  </TableCell>
                                )
                              )}
                          </TableRow>
                        ))
                      : ""}

                    <TableRow>
                      <TableCell className="totals_name_column1">
                        TOTAL
                      </TableCell>
                      {halfCountTotals.map((item, i) => (
                        <TableCell
                          align="center"
                          key={i}
                          className="totals_name"
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : values.period === 2 ? (
                  <TableBody>
                    {rows &&
                      rows.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell className="provider_name">
                            {item.providerName}
                          </TableCell>
                          {item.productivityTrendProviderServices &&
                            item.productivityTrendProviderServices.map(
                              (element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  {element.count
                                    ? parseFloat(
                                        element.count,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                      })
                                    : "0"}
                                </TableCell>
                              )
                            )}
                        </TableRow>
                      ))}

                    <TableRow>
                      <TableCell className="totals_name_column1">
                        TOTAL
                      </TableCell>
                      {countTotals.map((item, i) => (
                        <TableCell
                          align="center"
                          key={i}
                          className="totals_name"
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : (
                  ""
                )}
              </Table>
            ) : //group by facility
            values.group === 2 ? (
              <Table aria-label="sticky table">
                {/* Header 6 months  */}
                <TableHead>
                  {values.period === 1 ? (
                    <TableRow>
                      <TableCell className="prod_table_head_cell provider_name">
                        <TableSortLabel>
                          {/* {values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name} */}
                          {/* {filter === 1
                            ? values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name
                            : filter === 3
                            ? values.service === -1
                              ? "Total"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name
                            : "Total"} */}
                        </TableSortLabel>
                      </TableCell>
                      {/* {halfMonths.map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                      {halfRows.length > 0
                        ? halfRows[0].productivityTrendProviderServices.map(
                            (item, i) => (
                              <TableCell
                                key={i}
                                className="prod_table_head_cell"
                                align="center"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            )
                          )
                        : ""}
                    </TableRow>
                  ) : values.period === 2 ? (
                    <TableRow>
                      <TableCell className="prod_table_head_cell provider_name">
                        <TableSortLabel>
                          {/* {filter === 1
                            ? values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name
                            : filter === 3
                            ? values.service === -1
                              ? "Total"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name
                            : "Total"} */}
                        </TableSortLabel>
                      </TableCell>
                      {/* {months.map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                      {rows.length > 0
                        ? rows[0].productivityTrendProviderServices.map(
                            (item, i) => (
                              <TableCell
                                key={i}
                                className="prod_table_head_cell"
                                align="center"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            )
                          )
                        : ""}
                    </TableRow>
                  ) : (
                    ""
                  )}
                </TableHead>

                {values.period === 1 ? (
                  <TableBody>
                    {halfRows &&
                      halfRows.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell className="provider_name">
                            {item.facilityName}
                          </TableCell>
                          {item.productivityTrendProviderServices &&
                            item.productivityTrendProviderServices.map(
                              (element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  {element.count
                                    ? parseFloat(
                                        element.count,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                      })
                                    : "0"}
                                </TableCell>
                              )
                            )}
                        </TableRow>
                      ))}

                    <TableRow>
                      <TableCell className="totals_name_column1">
                        TOTAL
                      </TableCell>
                      {halfCountTotals.map((item, i) => (
                        <TableCell
                          align="center"
                          key={i}
                          className="totals_name"
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : values.period === 2 ? (
                  <TableBody>
                    {rows &&
                      rows.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell className="provider_name">
                            {item.facilityName}
                          </TableCell>
                          {item.productivityTrendProviderServices &&
                            item.productivityTrendProviderServices.map(
                              (element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  {element.count
                                    ? parseFloat(
                                        element.count,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                      })
                                    : "0"}
                                </TableCell>
                              )
                            )}
                        </TableRow>
                      ))}

                    <TableRow>
                      <TableCell className="totals_name_column1">
                        TOTAL
                      </TableCell>
                      {countTotals.map((item, i) => (
                        <TableCell
                          align="center"
                          key={i}
                          className="totals_name"
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : (
                  ""
                )}
              </Table>
            ) : (
              //group by service
              <Table aria-label="sticky table">
                <TableHead>
                  {values.period === 1 ? (
                    <TableRow>
                      <TableCell className="prod_table_head_cell provider_name">
                        <TableSortLabel>
                          {/* {filter === 1
                            ? values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name
                            : filter === 2
                            ? values.facility === -1
                              ? "Total"
                              : facilityTypes.filter(
                                  (item) => item.value === values.facility
                                )[0].name
                            : "Total"} */}
                        </TableSortLabel>
                      </TableCell>
                      {/* {halfMonths.map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                      {halfRows.length > 0
                        ? halfRows[0].productivityTrendProviderServices.map(
                            (item, i) => (
                              <TableCell
                                key={i}
                                className="prod_table_head_cell"
                                align="center"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            )
                          )
                        : ""}
                    </TableRow>
                  ) : values.period === 2 ? (
                    <TableRow>
                      <TableCell className="prod_table_head_cell provider_name">
                        <TableSortLabel>
                          {/* {filter === 1
                            ? values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name
                            : filter === 2
                            ? values.facility === -1
                              ? "Total"
                              : facilityTypes.filter(
                                  (item) => item.value === values.facility
                                )[0].name
                            : "Total"} */}
                        </TableSortLabel>
                      </TableCell>
                      {/* {months.map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item}
                          </TableCell>
                        ))} */}
                      {rows.length > 0
                        ? rows[0].productivityTrendProviderServices.map(
                            (item, i) => (
                              <TableCell
                                key={i}
                                align="center"
                                className="prod_table_head_cell"
                              >
                                {item.month ? months[item.month - 1] : ""}
                              </TableCell>
                            )
                          )
                        : ""}
                    </TableRow>
                  ) : (
                    ""
                  )}
                </TableHead>

                {values.period === 1 ? (
                  <TableBody>
                    {halfRows &&
                      halfRows.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell className="provider_name">
                            {item.serviceName}
                          </TableCell>
                          {item.productivityTrendProviderServices &&
                            item.productivityTrendProviderServices.map(
                              (element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  align="center"
                                  key={element.collectionId}
                                >
                                  {element.count
                                    ? parseFloat(
                                        element.count,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                      })
                                    : "0"}
                                </TableCell>
                              )
                            )}
                        </TableRow>
                      ))}

                    <TableRow>
                      <TableCell className="totals_name_column1">
                        TOTAL
                      </TableCell>
                      {halfCountTotals.map((item, i) => (
                        <TableCell
                          align="center"
                          key={i}
                          className="totals_name"
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : values.period === 2 ? (
                  <TableBody>
                    {rows &&
                      rows.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell className="provider_name">
                            {item.serviceName}
                          </TableCell>
                          {item.productivityTrendProviderServices &&
                            item.productivityTrendProviderServices.map(
                              (element) => (
                                <TableCell
                                  className="collection_sub_cell"
                                  key={element.collectionId}
                                  align="center"
                                >
                                  {element.count
                                    ? parseFloat(
                                        element.count,
                                        10
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 0,
                                      })
                                    : "0"}
                                </TableCell>
                              )
                            )}
                        </TableRow>
                      ))}

                    <TableRow>
                      <TableCell className="totals_name_column1">
                        TOTAL
                      </TableCell>
                      {countTotals.map((item, i) => (
                        <TableCell
                          align="center"
                          key={i}
                          className="totals_name"
                        >
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : (
                  ""
                )}
              </Table>
            )}
          </TableContainer>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CountTable;
