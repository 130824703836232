import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "components/DatePicker";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/NavigateNextRounded";
import ArrowDownIcon from "@material-ui/icons/ExpandMoreRounded";
import TablePagination from "@material-ui/core/TablePagination";
import FilterIcon from "@material-ui/icons/FilterList";
import { toast } from "react-hot-toast";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  getProductionDetailed,
  getClaimLevel,
  getEob,
  getNotes,
  exportDetails,
  downloadDetails,
  getExportStatus,
  updateApmtType,
  payorsList,
} from "Redux/Production/Production.actions";
import {
  getProvidersList,
  getServicesList,
  getFacilitiesList,
} from "Redux/Dashboard/Dashboard.actions";
import Notes from "../Notes";
import "./prodDetailed.css";
import { prodDetailsContext } from "context/ProdFilterContext";
import { repeatWhen } from "rxjs/operators";
import Eob from "../EOB";
import { Autocomplete } from "@material-ui/lab";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const statuses = [
  // { name: "All Statuses", value: 0 },
  { name: "Visit Completed", value: 1 },
  { name: "Created", value: 2 },
  { name: "Filed", value: 3 },
  { name: "Rejected", value: 4 },
  { name: "Denied", value: 5 },
  { name: "Sec Ready", value: 6 },
  { name: "Sec Pending", value: 7 },
  { name: "Ter Ready", value: 8 },
  { name: "Ter Pending", value: 9 },
  { name: "Pat Balance", value: 10 },
  { name: "Closed", value: 11 },
  { name: "Clar Opened", value: 12 },
  { name: "Clar Closed", value: 13 },
  { name: "On Hold", value: 14 },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

function Row(props) {
  const { row, claims, icds, services, selectedItem } = props;
  const [open, setOpen] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openEob, setOpenEob] = useState(false);
  // const editClose = (event) => {
  //   if (event === true) {
  //     setOpen(false);
  //   }
  // };

  const handleClick = (claimId) => {
    setOpen(!open);
    props.handleExpand(claimId);
  };

  const handleView = (claimId, provId) => {
    setOpenNotes(true);
    props.handleOpenNotes(claimId, provId);
  };

  const handleEob = (claimId, ipatientId) => {
    props.getEob(claimId, ipatientId);
  };

  const DateFormat = (date) => {
    let d = date.split("-");
    return `${d[1]}/${d[2]}/${d[0]}`;
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
  };
  const handleCloseEob = () => {
    setOpenEob(false);
  };

  const handleApmtType = (e, claimId) => {
    props.handleApmtType(e.target.value, claimId);
  };

  return (
    <>
      <TableRow>
        <TableCell className="arrow_icon">
          <div onClick={() => handleClick(row.claimId)}>
            {open ? <ArrowDownIcon /> : <ArrowRightIcon />}
          </div>
        </TableCell>
        {selectedItem.patient && (
          <TableCell className="table_cell_patient_name">
            {row.spatientName}
          </TableCell>
        )}

        {selectedItem.mrn && <TableCell>{row.smrn}</TableCell>}
        {selectedItem.dos && <TableCell>{DateFormat(row.sdos)}</TableCell>}
        {selectedItem.provider && (
          <TableCell className="table_cell_provider_name">
            {row.sproviderName}
          </TableCell>
        )}

        {selectedItem.payor && (
          <Tooltip title={row.payorName ? row.payorName : ""}>
            <TableCell style={{ cursor: "pointer" }} className="facility_name">
              {row.payorName ? row.payorName.substring(0, 10) : ""}
            </TableCell>
          </Tooltip>
        )}

        {selectedItem.facility && (
          <Tooltip title={row.facilityName ? row.facilityName : ""}>
            <TableCell style={{ cursor: "pointer" }} className="facility_name">
              {row.facilityName
                ? row.facilityName.substring(0, 10) + "..."
                : null}
            </TableCell>
          </Tooltip>
        )}

        {selectedItem.service && (
          <TableCell>
            <FormControl className="table_cell_dropdown_wrap">
              <Select
                value={row.serviceId}
                onChange={(e) => handleApmtType(e, row.claimId)}
              >
                <MenuItem value={0}>Other</MenuItem>
                {services &&
                  services.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </TableCell>
        )}
        {selectedItem.charges && (
          <TableCell>
            $
            {parseFloat(row.dcharges, 10).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </TableCell>
        )}
        {selectedItem.payments && (
          <TableCell>
            $
            {parseFloat(row.dpayments, 10).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </TableCell>
        )}
        {/* {selectedItem.balance && (
          <TableCell>
            $
            {parseFloat(row.dbalance, 10).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </TableCell>
        )} */}
        {selectedItem.patBalance && (
          <TableCell>
            $
            {parseFloat(row.patientBalance, 10).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </TableCell>
        )}
        {selectedItem.insBalance && (
          <TableCell>
            $
            {parseFloat(row.insuranceBalance, 10).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </TableCell>
        )}

        {selectedItem.claimStatus && (
          <TableCell className="table_cell_claim_status">
            {row.claimStatus ? statuses[row.claimStatus - 1].name : ""}
          </TableCell>
        )}
        {selectedItem.notes && (
          <TableCell
            className="table_viewBtn"
            onClick={() => handleView(row.claimId, row.iproviderId)}
          >
            View
          </TableCell>
        )}
        {selectedItem.eob &&
          (row.claimStatus === 1 || row.claimStatus === 2 ? (
            <TableCell className="table_viewBtn_noEob">EOB</TableCell>
          ) : (
            <TableCell
              className="table_viewBtn"
              onClick={() => {
                setOpenEob(true);
                handleEob(row.claimId, row.ipatientId);
              }}
            >
              EOB
            </TableCell>
          ))}
      </TableRow>

      <TableRow>
        <TableCell className="expand_cell" colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="expand_container">
              <div className="expand_first_card">
                {icds?.icd1 !== "0" && icds.icd1 !== "" && (
                  <div className="expand_card_item">ICD1: {icds.icd1}</div>
                )}
                {icds.icd2 !== "0" && icds.icd2 !== "" && (
                  <div className="expand_card_item">ICD2: {icds.icd2}</div>
                )}
                {icds.icd3 !== "0" && icds.icd3 !== "" && (
                  <div className="expand_card_item">ICD3: {icds.icd3}</div>
                )}
                {icds.icd4 !== "0" && icds.icd4 !== "" && (
                  <div className="expand_card_item">ICD4: {icds.icd4}</div>
                )}
                {icds.icd5 !== "0" && icds.icd5 !== "" && (
                  <div className="expand_card_item">ICD5: {icds.icd5}</div>
                )}
                {icds.icd6 !== "0" && icds.icd6 !== "" && (
                  <div className="expand_card_item">ICD6: {icds.icd6}</div>
                )}
                {icds.icd7 !== "0" && icds.icd7 !== "" && (
                  <div className="expand_card_item">ICD7: {icds.icd7}</div>
                )}
                {icds.icd8 !== "0" && icds.icd8 !== "" && (
                  <div className="expand_card_item">ICD8: {icds.icd8}</div>
                )}
              </div>
              <div className="expand_second_card scrollbarY--custom">
                <TableContainer
                  component={Paper}
                  elevation={0}
                  className="expand_table_container"
                >
                  <Table stickyHeader aria-label="table">
                    <TableHead className="expand_table_head" elevation={0}>
                      <TableRow>
                        <TableCell className="expand_table_head_cell">
                          DOS
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          CPT
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Unit
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Fee
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Billed
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Adjusted
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Ins Paid
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Pat Paid
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Ins Bal
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Pat Bal
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {claims &&
                        claims.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell>{DateFormat(item.fromDate)}</TableCell>
                            <TableCell>
                              {item.cptCode}
                              {item.modifier1 ? "-" + item.modifier1 : ""}
                              {item.modifier2 ? "," + item.modifier2 : ""}
                              {item.modifier3 ? "," + item.modifier3 : ""}
                              {item.modifier4 ? "," + item.modifier4 : ""}
                            </TableCell>
                            <TableCell>{item.unit}</TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.fee, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.billed, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.adjust, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.insPaid, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.patPaid, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.insBalance, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.patBalance, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={openNotes}
        onClose={() => handleCloseNotes()}
        maxWidth="lg"
        // fullWidth
        className="notes_dialog"
      >
        <DialogContent className="notes_dialogueContent scrollbarY--custom">
          <Notes handleCloseNotes={() => handleCloseNotes()} />
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="lg"
        open={openEob}
        onClose={() => handleCloseEob()}
        className="notes_dialogueContent scrollbarY--custom"
      >
        <DialogContent>
          <Eob handleCloseEob={() => handleCloseEob()} />
        </DialogContent>
      </Dialog>
    </>
  );
}

function ProductionDetailed(props) {
  const classes = useStyles();
  const clinic_id = localStorage.getItem("clinic_id");
  const [providers, setProviders] = useState([
    // {
    //   name: "All providers",
    //   value: -1,
    // },
  ]);
  const {
    values,
    setValues,
    provider,
    setProvider,
    services,
    setServices,
    service,
    setService,
    facilities,
    setFacilities,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    period,
    setPeriod,
    selectedItem,
    setSelectedItem,
    selectedStatus,
    setSelectedStatus,
    selectedFacility,
    setSelectedFacility,
    selectedPayor,
    setSelectedPayor,
    payor,
    setPayor,
  } = useContext(prodDetailsContext);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [grandTotal, setGrandTotal] = useState({});
  const [fileName, setFileName] = useState("");
  const [hideDownloadBtn, setHideDownloadBtn] = useState(true);
  const [hideCloseBtn, setHideCloseBtn] = useState(true);
  const [claims, setClaims] = useState([]);
  const [currentPayor, setCurrentPayor] = useState({ payor: "" });
  const [payorsId, setPayorsId] = useState("");
  const [icds, setIcds] = useState({});
  const isAllStatusSelected =
    statuses.length > 0 && selectedStatus.length === statuses.length;

  const isAllFacilitySelected =
    facilities.length > 0 && selectedFacility.length === facilities.length;

  const isAllProviderSelected =
    providers.length > 0 && provider.length === providers.length;

  const isAllServiceSelected =
    services.length > 0 && service.length === services.length;

  // const isAllPayorSelected =
  //   payor.length > 0 && selectedPayor.length === payor.length;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilter = Boolean(anchorEl);
  const [headCells, setHeadCells] = useState([
    {
      id: "mrn",
      label: "MRN",
    },
    {
      id: "dos",
      label: "DOS",
    },
    {
      id: "provider",
      label: "Provider",
    },
    {
      id: "service",
      label: "Service",
    },
    {
      id: "charges",
      label: "Charges",
    },
    {
      id: "payments",
      label: "Payments",
    },
    // {
    //   id: "balance",
    //   label: "Balance",
    // },
    {
      id: "insBalance",
      label: "Ins Bal",
    },
    {
      id: "patBalance",
      label: "Pat Bal",
    },
    {
      id: "claimStatus",
      label: "Claim Status",
    },
    {
      id: "notes",
      label: "Notes",
    },
    {
      id: "facility",
      label: "Facility",
    },
    {
      id: "payor",
      label: "Payor",
    },
    {
      id: "eob",
      label: "EOB",
    },
  ]);

  // Download Progress

  const [activeStep, setActiveStep] = useState(0);
  const [stepFailed, setStepFailed] = useState(false);
  const steps = stepFailed
    ? ["Starting", "Exporting", "Extracting", "Failed"]
    : ["Starting", "Exporting", "Extracting", "Completed"];

  const isStepFailed = (step) => {
    return step === 3;
  };

  /* state for 'export' modal */
  const [openExport, setOpenExport] = useState(false);

  const handleOpenExport = () => {
    setOpenExport(true);
    setActiveStep(0);
  };
  const handleCloseExport = () => {
    setOpenExport(false);
    setFileName("");
    setActiveStep(0);
  };

  /******************************* */

  const periods = [
    { name: "Custom", value: 1 },
    { name: "Last 12 months", value: 2 },
  ];

  const formatDate = (date) => {
    if (date === null) {
      return null;
    } else if (date === "") {
      return "";
    }
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    if (isNaN(year)) {
      return "";
    } else {
      return [year, month, day].join("-");
    }
  };

  const lastYear = (date) => {
    if (date === null) {
      return null;
    } else if (date === "") {
      return "";
    }
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear() - 1;
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    if (isNaN(year)) {
      return "";
    } else {
      return [year, month, day].join("-");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (period === 1) {
      props.getProductionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: startDate ? formatDate(startDate) : "",
        sendDate: endDate ? formatDate(endDate) : "",
        iproviderId: 0,
        // payorId:
        //   selectedPayor.length > 0 && isAllPayorSelected
        //     ? "0"
        //     : selectedPayor.length > 0
        //     ? selectedPayor.map((item) => item.value).join(",")
        //     : "0",
        payorId: payorsId ? payorsId : "0",
        blLedgerDos: true,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllStatusSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        facilityId:
          selectedFacility.length > 0 && isAllFacilitySelected
            ? "0"
            : selectedFacility.length > 0
            ? selectedFacility.map((item) => item.value).join(",")
            : "0",
        serviceId:
          service.length > 0 && isAllServiceSelected
            ? "0"
            : service.length > 0
            ? service.map((item) => item.value).join(",")
            : "0",
        sproviderIds:
          provider.length > 0 && isAllProviderSelected
            ? "0"
            : provider.length > 0
            ? provider.map((item) => item.value).join(",")
            : "0",
        offset: newPage,
      });
    } else if (period === 2) {
      props.getProductionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: lastYear(new Date()),
        sendDate: formatDate(new Date()),
        iproviderId: 0,
        // payorId:
        //   selectedPayor.length > 0 && isAllPayorSelected
        //     ? "0"
        //     : selectedPayor.length > 0
        //     ? selectedPayor.map((item) => item.value).join(",")
        //     : "0",
        payorId: payorsId ? payorsId : "0",
        blLedgerDos: true,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllStatusSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        facilityId:
          selectedFacility.length > 0 && isAllFacilitySelected
            ? "0"
            : selectedFacility.length > 0
            ? selectedFacility.map((item) => item.value).join(",")
            : "0",
        serviceId:
          service.length > 0 && isAllServiceSelected
            ? "0"
            : service.length > 0
            ? service.map((item) => item.value).join(",")
            : "0",
        sproviderIds:
          provider.length > 0 && isAllProviderSelected
            ? "0"
            : provider.length > 0
            ? provider.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: newPage,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenNotes = (claim_id, p_id) => {
    props.getNotes({
      clinicId: clinic_id,
      claimId: claim_id,
      providerId: 0,
    });
  };

  const handleExpand = (claim_id) => {
    // setExpand(!expand);
    props.getClaimLevel({
      clinicId: clinic_id,
      claimId: claim_id,
    });
    // props.getEob();
  };

  useEffect(() => {
    let newItem = [];
    Object.keys(selectedItem).forEach((x) => {
      headCells.forEach((j) => {
        if (x === j.id) {
          newItem.push({ ...j, status: selectedItem[x] });
        }
      });
    });
    setHeadCells(newItem);
    newItem = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleFilterCheckbox = (e) => {
    setSelectedItem({ ...selectedItem, [e.target.name]: e.target.checked });
  };

  const handleFilterColumn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterColumnClose = () => {
    setAnchorEl(null);
  };

  // const handleProviderChange = (event) => {
  //   setProvider(event.target.value);
  // };

  const handleServiceChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setService(service.length === services.length ? [] : services);
      return;
    }
    setService(value);
  };

  const handleFacilityChange = (event) => {
    const value = event.target.value;
    // console.log(value);
    if (value[value.length - 1] === "all") {
      setSelectedFacility(
        selectedFacility.length === facilities.length ? [] : facilities
      );
      return;
    }
    setSelectedFacility(value);
  };

  // const handlePayorChange = (event) => {
  //   const value = event.target.value;
  //   // console.log(value);
  //   if (value[value.length - 1] === "all") {
  //     setSelectedPayor(selectedPayor.length === payor.length ? [] : payor);
  //     return;
  //   }
  //   setSelectedPayor(value);
  // };

  const handleProviderChange = (event) => {
    const value = event.target.value;
    console.log(value);
    if (value[value.length - 1] === "all") {
      setProvider(provider.length === providers.length ? [] : providers);
      return;
    }
    setProvider(value);
  };

  const handleApmtType = (apptId, claimId) => {
    props.updateApmtType({
      clinicId: clinic_id,
      apptTypeId: apptId,
      claimId: claimId,
    });
    handleFilter();
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    console.log("statuses:", value);
    if (value[value.length - 1] === "all") {
      setSelectedStatus(
        selectedStatus.length === statuses.length ? [] : statuses
      );
      return;
    }
    setSelectedStatus(value);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e);
  };

  const handleEob = (claimId, ipatientId) => {
    props.getEob({ claimId: claimId, patientId: ipatientId });
  };

  useEffect(() => {
    setValues({
      period: period,
      provider:
        provider.length > 0 && isAllProviderSelected
          ? "0"
          : provider.length > 0
          ? provider.map((item) => item.value).join(",")
          : "0",
      service: service === -1 ? 0 : service,
      status:
        selectedStatus.length > 0 && isAllStatusSelected
          ? "0"
          : selectedStatus.length > 0
          ? selectedStatus.map((item) => item.value).join(",")
          : "0",
      // status: 0,
      startDate: startDate,
      endDate: endDate,
      facility:
        selectedFacility.length > 0 && isAllFacilitySelected
          ? "0"
          : selectedFacility.length > 0
          ? selectedFacility.map((item) => item.value).join(",")
          : "0",
      serviceId:
        service.length > 0 && isAllServiceSelected
          ? "0"
          : service.length > 0
          ? service.map((item) => item.value).join(",")
          : "0",
    });
    if (period === 1) {
      props.getProductionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: startDate ? formatDate(startDate) : "",
        sendDate: endDate ? formatDate(endDate) : "",
        // iproviderId: provider === -1 ? 0 : provider,
        iproviderId: 0,
        // payorId:
        //   selectedPayor.length > 0 && isAllPayorSelected
        //     ? "0"
        //     : selectedPayor.length > 0
        //     ? selectedPayor.map((item) => item.value).join(",")
        //     : "0",
        payorId: payorsId ? payorsId : "0",
        blLedgerDos: true,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        // serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllStatusSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        facilityId:
          selectedFacility.length > 0 && isAllFacilitySelected
            ? "0"
            : selectedFacility.length > 0
            ? selectedFacility.map((item) => item.value).join(",")
            : "0",
        serviceId:
          service.length > 0 && isAllServiceSelected
            ? "0"
            : service.length > 0
            ? service.map((item) => item.value).join(",")
            : "0",
        sproviderIds:
          provider.length > 0 && isAllProviderSelected
            ? "0"
            : provider.length > 0
            ? provider.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: page,
      });
    } else if (period === 2) {
      props.getProductionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: lastYear(new Date()),
        sendDate: formatDate(new Date()),
        iproviderId: 0,
        // payorId:
        //   selectedPayor.length > 0 && isAllPayorSelected
        //     ? "0"
        //     : selectedPayor.length > 0
        //     ? selectedPayor.map((item) => item.value).join(",")
        //     : "0",
        payorId: payorsId ? payorsId : "0",
        blLedgerDos: true,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        // serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllStatusSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        facilityId:
          selectedFacility.length > 0 && isAllFacilitySelected
            ? "0"
            : selectedFacility.length > 0
            ? selectedFacility.map((item) => item.value).join(",")
            : "0",
        serviceId:
          service.length > 0 && isAllServiceSelected
            ? "0"
            : service.length > 0
            ? service.map((item) => item.value).join(",")
            : "0",
        sproviderIds:
          provider.length > 0 && isAllProviderSelected
            ? "0"
            : provider.length > 0
            ? provider.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: page,
      });
    }
  }, []);

  const handleFilter = () => {
    setValues({
      period: period,
      provider:
        provider.length > 0 && isAllProviderSelected
          ? "0"
          : provider.length > 0
          ? provider.map((item) => item.value).join(",")
          : "0",
      service: service === -1 ? 0 : service,
      status:
        selectedStatus.length > 0 && isAllStatusSelected
          ? "0"
          : selectedStatus.length > 0
          ? selectedStatus.map((item) => item.value).join(",")
          : "0",
      // status: 0,
      startDate: startDate,
      endDate: endDate,
      facility:
        selectedFacility.length > 0 && isAllFacilitySelected
          ? "0"
          : selectedFacility.length > 0
          ? selectedFacility.map((item) => item.value).join(",")
          : "0",
      serviceId:
        service.length > 0 && isAllServiceSelected
          ? "0"
          : service.length > 0
          ? service.map((item) => item.value).join(",")
          : "0",
      payorId: payorsId ? payorsId : "0",
    });
    if (period === 1) {
      props.getProductionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: startDate ? formatDate(startDate) : "",
        sendDate: endDate ? formatDate(endDate) : "",
        // iproviderId: provider === -1 ? 0 : provider,
        iproviderId: 0,
        // payorId:
        //   selectedPayor.length > 0 && isAllPayorSelected
        //     ? "0"
        //     : selectedPayor.length > 0
        //     ? selectedPayor.map((item) => item.value).join(",")
        //     : "0",
        payorId: payorsId ? payorsId : "0",
        blLedgerDos: true,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        // serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllStatusSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        facilityId:
          selectedFacility.length > 0 && isAllFacilitySelected
            ? "0"
            : selectedFacility.length > 0
            ? selectedFacility.map((item) => item.value).join(",")
            : "0",
        serviceId:
          service.length > 0 && isAllServiceSelected
            ? "0"
            : service.length > 0
            ? service.map((item) => item.value).join(",")
            : "0",
        sproviderIds:
          provider.length > 0 && isAllProviderSelected
            ? "0"
            : provider.length > 0
            ? provider.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: page,
      });
    } else if (period === 2) {
      props.getProductionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: lastYear(new Date()),
        sendDate: formatDate(new Date()),
        iproviderId: 0,
        // payorId:
        //   selectedPayor.length > 0 && isAllPayorSelected
        //     ? "0"
        //     : selectedPayor.length > 0
        //     ? selectedPayor.map((item) => item.value).join(",")
        //     : "0",
        payorId: payorsId ? payorsId : "0",
        blLedgerDos: true,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        // serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllStatusSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        facilityId:
          selectedFacility.length > 0 && isAllFacilitySelected
            ? "0"
            : selectedFacility.length > 0
            ? selectedFacility.map((item) => item.value).join(",")
            : "0",
        serviceId:
          service.length > 0 && isAllServiceSelected
            ? "0"
            : service.length > 0
            ? service.map((item) => item.value).join(",")
            : "0",
        sproviderIds:
          provider.length > 0 && isAllProviderSelected
            ? "0"
            : provider.length > 0
            ? provider.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: page,
      });
    }
  };

  const handleExport = () => {
    if (period === 1) {
      props.exportDetails({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: startDate ? formatDate(startDate) : "",
        sendDate: endDate ? formatDate(endDate) : "",
        // iproviderId: provider === -1 ? 0 : provider,
        iproviderId: 0,
        // payorId:
        //   selectedPayor.length > 0 && isAllPayorSelected
        //     ? "0"
        //     : selectedPayor.length > 0
        //     ? selectedPayor.map((item) => item.value).join(",")
        //     : "0",
        payorId: payorsId ? payorsId : "0",
        blLedgerDos: true,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        // serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllStatusSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        facilityId:
          selectedFacility.length > 0 && isAllFacilitySelected
            ? "0"
            : selectedFacility.length > 0
            ? selectedFacility.map((item) => item.value).join(",")
            : "0",
        serviceId:
          service.length > 0 && isAllServiceSelected
            ? "0"
            : service.length > 0
            ? service.map((item) => item.value).join(",")
            : "0",
        sproviderIds:
          provider.length > 0 && isAllProviderSelected
            ? "0"
            : provider.length > 0
            ? provider.map((item) => item.value).join(",")
            : "0",
      });
    } else if (period === 2) {
      props.exportDetails({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: lastYear(new Date()),
        sendDate: formatDate(new Date()),
        iproviderId: 0,
        // payorId:
        //   selectedPayor.length > 0 && isAllPayorSelected
        //     ? "0"
        //     : selectedPayor.length > 0
        //     ? selectedPayor.map((item) => item.value).join(",")
        //     : "0",
        payorId: payorsId ? payorsId : "0",
        blLedgerDos: true,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        // serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllStatusSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        facilityId:
          selectedFacility.length > 0 && isAllFacilitySelected
            ? "0"
            : selectedFacility.length > 0
            ? selectedFacility.map((item) => item.value).join(",")
            : "0",
        serviceId:
          service.length > 0 && isAllServiceSelected
            ? "0"
            : service.length > 0
            ? service.map((item) => item.value).join(",")
            : "0",
        sproviderIds:
          provider.length > 0 && isAllProviderSelected
            ? "0"
            : provider.length > 0
            ? provider.map((item) => item.value).join(",")
            : "0",
      });
    }
    handleOpenExport();
    setTimeout(() => props.getExportStatus(clinic_id), 5000);
  };

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.exportResponse &&
      props.prodData.exportResponse.response
    ) {
      if (props.prodData.exportResponse.response.responseCode === 0) {
        let data = props.prodData.exportResponse.response.data.fileName;
        setFileName(data);
        setActiveStep(1);
      } else {
        setActiveStep(3);
        setStepFailed(true);
        setHideCloseBtn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.exportResponse.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.exportStatus &&
      props.prodData.exportStatus.response
    ) {
      if (props.prodData.exportStatus.response.responseCode === 0) {
        let status = props.prodData.exportStatus.response.data.status;
        if (status === 1) {
          setActiveStep(4);
          setHideDownloadBtn(false);
          setHideCloseBtn(false);
        } else {
          const interval = setInterval(() => {
            if (status === 0) {
              setActiveStep(2);
              props.getExportStatus(clinic_id);
            } else if (status === 1) {
              setActiveStep(4);
              setHideDownloadBtn(false);
            }
          }, 5000);
          return () => clearInterval(interval);
        }
      } else {
        setActiveStep(3);
        setStepFailed(true);
        setHideCloseBtn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.exportStatus.response]);

  const handleDownload = () => {
    props.downloadDetails(fileName.toString());
  };

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.downloadResponse &&
      props.prodData.downloadResponse.response
    ) {
      if (fileName) {
        let blobData = props.prodData.downloadResponse.response;
        let fileType = "text/csv";
        download(blobData, fileName, fileType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.downloadResponse.response]);

  const download = (content, fileName, contentType) => {
    var a = document.createElement("a");
    var blob = new Blob([content], {
      type: contentType,
    });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.updateApmtResponse &&
      props.prodData.updateApmtResponse.response
    ) {
      if (props.prodData.updateApmtResponse.response.responseCode === 0) {
        toast.success("Appointment Type Updated");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.updateApmtResponse.response]);

  useEffect(() => {
    props.getProvidersList(clinic_id);
    props.getFacilitiesList(clinic_id);
    props.getServicesList({
      start: 0,
      limit: 100,
    });
    props.payorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.providersList &&
      props.dashboardData.providersList.response
    ) {
      if (props.dashboardData.providersList.response.responseCode === 0) {
        let data = props.dashboardData.providersList.response.data;
        let providersArray = data
          .filter((item) => item.active === 1)
          .map((item) => {
            return {
              name:
                item.firstName + " " + item.middleName + " " + item.lastName,
              value: item.id,
            };
          });
        // let newArray = [
        //   {
        //     name: "All providers",
        //     value: -1,
        //   },
        //   ...providersArray,
        // ];
        // console.log(providersArray);
        setProviders(providersArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.providersList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.servicesList &&
      props.dashboardData.servicesList.response
    ) {
      if (props.dashboardData.servicesList.response.responseCode === 0) {
        let data = props.dashboardData.servicesList.response.data.results;
        // console.log(data);
        let servicesArray = data.map((item) => {
          return {
            name: item.name,
            value: item.id,
          };
        });
        setServices(servicesArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.servicesList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.facilitiesList &&
      props.dashboardData.facilitiesList.response
    ) {
      if (props.dashboardData.facilitiesList.response.responseCode === 0) {
        let data = props.dashboardData.facilitiesList.response.data;
        let facilitiesArray = data
          .filter((item) => item.active === 1)
          .map((item) => {
            return {
              name: item.locationName + ", " + item.city,
              value: item.id,
            };
          });
        setFacilities(facilitiesArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.facilitiesList.response]);

  useEffect(() => {
    if (props.prodData.payorsList.response) {
      if (props.prodData.payorsList.response.responseCode === 0) {
        let data = props.prodData.payorsList.response.data;
        // console.log(data);
        let payorsArray = data.map((item) => {
          return {
            name: item.name,
            value: item.businessId,
          };
        });
        setPayor(payorsArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.payorsList.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.prodDetailed &&
      props.prodData.prodDetailed.response
    ) {
      if (props.prodData.prodDetailed.response.responseCode === 0) {
        let data = props.prodData.prodDetailed.response.data;
        setTotalCount(data.totalRecords);
        setRows(data.result.providerSummary);
        // console.log(data.result.providerSummary);
        setGrandTotal(data.result.reportSummary);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.prodDetailed.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.claimLevel &&
      props.prodData.claimLevel.response
    ) {
      if (props.prodData.claimLevel.response.responseCode === 0) {
        let data = props.prodData.claimLevel.response.data;
        setClaims(data.cptReportDtoList);
        setIcds(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.claimLevel.response]);

  // useEffect(() => {
  //   if (
  //     props.prodData &&
  //     props.prodData.notes &&
  //     props.prodData.notes.response
  //   ) {
  //     if (props.prodData.notes.response.responseCode === 0) {
  //       let data = props.prodData.notes.response.data;
  //       console.log(data);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.prodData.notes.response]);

  const handlePayor = (v) => {
    // console.log(v);
    if (v === null) {
      setCurrentPayor({ ...currentPayor, payor: "" });
      setPayorsId('')
    }
    if (v) {
      setCurrentPayor({ ...currentPayor, payor: v.name });
      // setSelectedPayor(v.value);
      setPayorsId(v.value);
    }
  };

  return (
    <div className="prodDetailed_root">
      {/* <div className="heading">Production Detailed</div> */}

      <div className="prodDetailed_searchbar_container">
        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap">
            <InputLabel>Provider</InputLabel>
            <Select
              multiple
              value={provider}
              renderValue={(selected) =>
                `${selected.length} of ${providers.length} selected`
              }
              onChange={handleProviderChange}
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllProviderSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllProviderSelected}
                    indeterminate={
                      provider.length > 0 && provider.length < providers.length
                    }
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="All Providers"
                />
              </MenuItem>
              {providers.map((option) => (
                <MenuItem key={option.name} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        provider.findIndex((i) => i.name === option.name) > -1
                      }
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap">
            <InputLabel>Service</InputLabel>
            <Select
              multiple
              value={service}
              onChange={(e) => handleServiceChange(e)}
              renderValue={(selected) =>
                `${selected.length} of ${services.length} selected`
              }
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllServiceSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllServiceSelected}
                    indeterminate={
                      service.length > 0 && service.length < services.length
                    }
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="All Services"
                />
              </MenuItem>
              {services.map((option) => (
                <MenuItem key={option.name} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        service.findIndex((i) => i.name === option.name) > -1
                      }
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap">
            <InputLabel>Facility</InputLabel>
            <Select
              multiple
              value={selectedFacility}
              onChange={(e) => handleFacilityChange(e)}
              renderValue={(selected) =>
                `${selected.length} of ${facilities.length} selected`
              }
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllFacilitySelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllFacilitySelected}
                    indeterminate={
                      selectedFacility.length > 0 &&
                      selectedFacility.length < facilities.length
                    }
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="All Facilities"
                />
              </MenuItem>
              {facilities.map((option) => (
                <MenuItem key={option.name} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        selectedFacility.findIndex(
                          (i) => i.name === option.name
                        ) > -1
                      }
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap_status">
            <InputLabel>Claim Status</InputLabel>
            <Select
              multiple
              value={selectedStatus}
              onChange={handleStatusChange}
              // input={<Input />}
              renderValue={(selected) =>
                `${selected.length} of ${statuses.length} selected`
              }
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllStatusSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllStatusSelected}
                    indeterminate={
                      selectedStatus.length > 0 &&
                      selectedStatus.length < statuses.length
                    }
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="All Statuses"
                />
              </MenuItem>
              {statuses.map((option) => (
                <MenuItem key={option.name} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        selectedStatus.findIndex(
                          (i) => i.name === option.name
                        ) > -1
                      }
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap_payor">
            {/* <InputLabel>Payor</InputLabel> */}
            {/* <Select
              multiple
              value={selectedPayor}
              onChange={(e) => handlePayorChange(e)}
              renderValue={(selected) =>
                `${selected.length} of ${payor.length} selected`
              }
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllPayorSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllPayorSelected}
                    indeterminate={
                      selectedPayor.length > 0 &&
                      selectedPayor.length < payor.length
                    }
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="All Payors"
                />
              </MenuItem>
              {payor.map((option) => (
                <MenuItem key={option.name} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        selectedFacility.findIndex(
                          (i) => i.name === option.name
                        ) > -1
                      }
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select> */} 
            <Autocomplete
              options={payor}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handlePayor(v)}
              value={{ name: currentPayor.payor }}
              renderInput={(params) => (
                <TextField
                  style={{ width: "130px" }}
                  {...params}
                  label="Payor"
                />
              )}
            />
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select value={period} onChange={(e) => handlePeriodChange(e)}>
              {periods &&
                periods.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        {period === 1 ? (
          <>
            <div className="production_date_dropdown">
              <DatePicker
                required
                className="production_datePicker"
                label="From"
                value={startDate}
                maxDate={new Date()}
                onChange={(e) => handleStartDateChange(e)}
              />
            </div>

            <div className="production_date_dropdown">
              <DatePicker
                required
                className="production_datePicker"
                label="To"
                value={endDate}
                maxDate={new Date()}
                onChange={(e) => handleEndDateChange(e)}
              />
            </div>
          </>
        ) : period === 2 ? (
          <></>
        ) : (
          ""
        )}

        <div className="prodDetailed_Btn_wrap">
          <div className="filterBtn_wrap">
            <Button
              className="filterBtn"
              variant="contained"
              color="primary"
              onClick={() => handleFilter()}
            >
              Filter
            </Button>
          </div>
          <div className="exportBtn_wrap">
            <Button
              className="exportBtn"
              variant="outlined"
              color="primary"
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
          {/* <div className="reportBtn_wrap">
            <Button
              className="reportBtn"
              variant="outlined"
              color="primary"
              onClick={handleDenialReport}
            >
              Denial Report
            </Button>
          </div> */}
          <div className="filterColumn_icon">
            <IconButton
              id="column-filter"
              aria-controls={openFilter ? "column-menu" : undefined}
              aria-expanded={openFilter ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleFilterColumn}
            >
              <FilterIcon />
            </IconButton>
          </div>

          <Menu
            className="columnMenu"
            id="column-menu"
            MenuListProps={{
              "aria-labelledby": "column-filter",
            }}
            anchorEl={anchorEl}
            open={openFilter}
            onClose={handleFilterColumnClose}
            PaperProps={{
              style: {
                width: "55ch",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <FormGroup className="column_filter_container">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                  {headCells.map((x, i) => (
                    <Grid item xs={4} key={i}>
                      <FormControlLabel
                        className="column_filter_text"
                        control={<Checkbox size="small" color="primary" />}
                        onChange={(e) => handleFilterCheckbox(e)}
                        name={x.id}
                        label={x.label}
                        checked={x.status}
                        labelPlacement="end"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </FormGroup>
          </Menu>
        </div>
      </div>
      <div className="table_container">
        <div className="appsTab__container">
          <TableContainer
            component={Paper}
            elevation={0}
            className="prod_table_container"
          >
            <Table
              className="prodDetailed_table"
              stickyHeader
              size="small"
              aria-label="table"
            >
              <TableHead className="prod_table_head" elevation={0}>
                <TableRow className="prod_table_head">
                  <TableCell className="prod_detailed_table_head_cell arrow_icon"></TableCell>
                  <TableCell className="prod_detailed_table_head_cell patient_name">
                    Patient
                  </TableCell>
                  {headCells &&
                    headCells
                      .filter((x) => x.status === true)
                      .map((x) => (
                        <TableCell
                          key={x.id}
                          className={
                            x.id === "patient"
                              ? "prod_detailed_table_head_cell patient_name"
                              : "prod_detailed_table_head_cell"
                          }
                        >
                          {x.label}
                        </TableCell>
                      ))}
                  {/* <TableCell
                    className={
                      //  "prod_detailed_table_head_cell patient_name"
                      "prod_detailed_table_head_cell"
                    }
                  >
                    Actions
                  </TableCell> */}
                  <TableCell className="prod_detailed_table_head_cell arrow_icon"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row, i) => (
                    <Row
                      key={i}
                      row={row}
                      claims={claims}
                      // getEob={(claimId) => handleEob(claimId)}
                      getEob={handleEob}
                      icds={icds}
                      handleExpand={handleExpand}
                      handleOpenNotes={handleOpenNotes}
                      services={services}
                      handleApmtType={handleApmtType}
                      selectedItem={selectedItem}
                    />
                  ))}

                {rows.length === 0 || JSON.stringify(grandTotal) === "{}" ? (
                  ""
                ) : (
                  <TableRow>
                    <TableCell className="arrow_icon"></TableCell>
                    <TableCell className="grand_total_name" align="left">
                      Grand Total
                    </TableCell>
                    {/* {selectedItem.patient ? <TableCell></TableCell> : null} */}
                    {selectedItem.mrn ? <TableCell></TableCell> : null}
                    {selectedItem.dos ? <TableCell></TableCell> : null}
                    {selectedItem.provider ? <TableCell></TableCell> : null}
                    {selectedItem.service ? <TableCell></TableCell> : null}
                    {selectedItem.payor ? <TableCell></TableCell> : null}
                    {selectedItem.facility ? <TableCell></TableCell> : null}
                    {selectedItem.charges ? (
                      <TableCell className="totals_name">
                        $
                        {parseFloat(
                          grandTotal.dtotalCharges,
                          10
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                    ) : null}
                    {selectedItem.payments ? (
                      <TableCell className="totals_name">
                        $
                        {parseFloat(
                          grandTotal.dtotalPayments,
                          10
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                    ) : null}
                    {selectedItem.patBalance ? (
                      <TableCell className="totals_name">
                        $
                        {parseFloat(
                          grandTotal.dtotalPatientBalance,
                          10
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                    ) : null}
                    {selectedItem.insBalance ? (
                      <TableCell className="totals_name">
                        $
                        {parseFloat(
                          grandTotal.dtotalInsuranceBalance,
                          10
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                    ) : null}
                    {/* <TableCell className="totals_name"></TableCell> */}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 150, 200, 250, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <Dialog
        open={openExport}
        // onClose={handleCloseExport}
        fullWidth={true}
        maxWidth="lg"
      >
        <div className="progressBar">
          <Stepper className="stepper" activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const labelProps = {};
              if (stepFailed) {
                if (isStepFailed(index)) {
                  labelProps.error = true;
                }
              }
              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        <div className="export_btn_container">
          <div>
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={hideDownloadBtn}
              onClick={handleDownload}
              className="download_btn"
            >
              Download
            </Button>
          </div>
          <div className="download_cancel_btn">
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={hideCloseBtn}
              onClick={handleCloseExport}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
  prodData: state.production,
});

const mapDispatchToProps = (dispatch) => ({
  getProvidersList: (values) => dispatch(getProvidersList(values)),
  getServicesList: (values) => dispatch(getServicesList(values)),
  getFacilitiesList: (values) => dispatch(getFacilitiesList(values)),
  getProductionDetailed: (values) => dispatch(getProductionDetailed(values)),
  getClaimLevel: (values) => dispatch(getClaimLevel(values)),
  getEob: (values) => dispatch(getEob(values)),
  getNotes: (values) => dispatch(getNotes(values)),
  exportDetails: (values) => dispatch(exportDetails(values)),
  downloadDetails: (values) => dispatch(downloadDetails(values)),
  getExportStatus: (values) => dispatch(getExportStatus(values)),
  updateApmtType: (values) => dispatch(updateApmtType(values)),
  payorsList: (values) => dispatch(payorsList(values)),
});

ProductionDetailed.propTypes = {
  dashboardData: PropTypes.object,
  prodData: PropTypes.object,
  getProvidersList: PropTypes.func,
  getServicesList: PropTypes.func,
  getFacilitiesList: PropTypes.func,
  getProductionDetailed: PropTypes.func,
  getClaimLevel: PropTypes.func,
  getEob: PropTypes.func,
  getNotes: PropTypes.func,
  exportDetails: PropTypes.func,
  downloadDetails: PropTypes.func,
  getExportStatus: PropTypes.func,
  updateApmtType: PropTypes.func,
  payorsList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionDetailed);
