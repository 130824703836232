import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "./Redux/store";
import App from "./App";
import "./index.css";
import ProdContext from "context/ProdFilterContext";
import CollectionContext from "context/CollectionFilterContext";
import Context from "context/AppContextProvider";
import ProdSummaryContext from "context/ProdSummaryContext";
import DenialReportContext from "context/DenialReportContext";
import CollSummaryContext from "context/CollSummaryContext";
import BankReconContext from "context/BankReconContextProvider";
import DashboardContext from "context/DashboardContextProvider";

const theme = createTheme({
  typography: {
    fontFamily: `Inter, sans-serif`,
  },
  palette: {
    primary: {
      // main: '#3cd970',
      // main: '#41dd75',
      main: "#14A6A6",
    },
  },
});

const contexts = [
  ProdContext,
  CollectionContext,
  ProdSummaryContext,
  DenialReportContext,
  CollSummaryContext,
  BankReconContext,
  DashboardContext,
];

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Context components={contexts}>
          <App />
        </Context>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
