import { ofType } from "redux-observable";
import { switchMap, map, takeUntil } from "rxjs/operators";
// import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from "Utils";
import { Apis } from "Redux/APIs";
import environment from "../../environments/environment";

import {
  REQUEST_CANCEL,
  CLINIC_DETAILS,
  PROVIDERS_LIST,
  FACILITIES_LIST,
  SERVICES_LIST,
  PRODUCTION_TREND,
  COLLECTION_TREND,
  FORECAST,
  NOT_FILED_CLAIMS,
  RECEIVABLE_NET,
  RECEIVABLE_GROSS,
} from "./Dashboard.types";

import {
  getClinicDetailsRes,
  getProvidersListRes,
  getFacilitiesListRes,
  getServicesListRes,
  getProductionTrendRes,
  getCollectionTrendRes,
  getForecastRes,
  getNotFiledClaimsRes,
  getReceivableNetRes,
  getReceivableGrossRes,
} from "./Dashboard.actions";

const { baseUrl, clinicBaseUrl } = environment;
const DashboardEpic = {};

DashboardEpic.getClinicDetails = (action$) =>
  action$.pipe(
    ofType(CLINIC_DETAILS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getClinicDetails}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getClinicDetailsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getProvidersList = (action$) =>
  action$.pipe(
    ofType(PROVIDERS_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getProvidersList}?clinicId=${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getProvidersListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getFacilitiesList = (action$) =>
  action$.pipe(
    ofType(FACILITIES_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getFacilitiesList}?clinicId=${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getFacilitiesListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getServicesList = (action$) =>
  action$.pipe(
    ofType(SERVICES_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${clinicBaseUrl}/trillium-clinic-service/v1/appointmentType/list/all?start=${action.payload.start}&limit=${action.payload.limit}`,
        method: "GET",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => getServicesListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getProductionTrend = (action$) =>
  action$.pipe(
    ofType(PRODUCTION_TREND),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getProductionTrend}/${action.payload.clinicId}?providerId=${action.payload.providerId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getProductionTrendRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getCollectionTrend = (action$) =>
  action$.pipe(
    ofType(COLLECTION_TREND),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getCollectionTrend}/${action.payload.clinicId}?providerId=${action.payload.providerId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getCollectionTrendRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getForecast = (action$) =>
  action$.pipe(
    ofType(FORECAST),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getForecast}/${action.payload.clinicId}?providerId=${action.payload.providerId}&facilityId=${action.payload.facilityId}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getForecastRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getNotFiledClaims = (action$) =>
  action$.pipe(
    ofType(NOT_FILED_CLAIMS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getNotFiledClaims}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getNotFiledClaimsRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getReceivableNet = (action$) =>
  action$.pipe(
    ofType(RECEIVABLE_NET),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getReceivableNet}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getReceivableNetRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

DashboardEpic.getReceivableGross = (action$) =>
  action$.pipe(
    ofType(RECEIVABLE_GROSS),
    switchMap((action) =>
      UtilsAjax({
        url: `${baseUrl}/${Apis.Dashboard_getReceivableGross}/${action.payload}`,
        method: "GET",
      }).pipe(
        map(
          (response) => getReceivableGrossRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

export default DashboardEpic;
