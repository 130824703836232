import React, { useState } from "react";
import { createContext } from "react";

export const dashboardContext = createContext();

const DashboardContext = ({ children }) => {
  const [provider, setProvider] = useState(-1);
  return (
    <dashboardContext.Provider
      value={{
        provider,
        setProvider,
      }}
    >
      {children}
    </dashboardContext.Provider>
  );
};

export default DashboardContext;
